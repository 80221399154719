import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import axiosInstance from "../../../../utils/axiosInstance";
import Maximize from "../../../../components/Maximize";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import LineChart, { LineData } from "../../../../components/LineChart";
import DynamicForm, { ExtendedFieldConfig, FieldOption } from "../../../../components/DynamicForm";
import { z } from "zod";
import { GEO_LIFT_AGGREGATE_LIFT_API, GEO_LIFT_MARKET_SELECTION, GEO_LIFT_POST_TEST_API, GEO_LIFT_POWER_PLOT_API, SEASONALITY_API } from "../../../../constants/API";
import PowerCurveChart from "../../../../components/PowerCurveChart";
import GeoLiftPowerCurve from "../../../../components/GeoLiftPowerCurve";
import Papa from "papaparse";
import { getRandomColor, getRandomColorWithOpacity } from "../../../../utils/utils";
import { cloneDeep } from "lodash";
import InfoIcon from "../../../../components/InfoIcon";
import MultiBarLineChartECharts from "../../../../components/BarChart";
import GeoStudyResults from "../../../../components/GeoStudyResults";
import { getTenantId } from '../../../../utils/auth';

const dropDownOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

// Helper function to parse location string into array of markets
const parseLocationString = (location: string): string[] => {
  return location
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll("'", "")
    .split(',')
    .map(loc => loc.trim());
};

// Helper function to format markets array back to location string
const formatMarketsToLocation = (markets: string[]): string => {
  return `(${markets.map(market => `'${market.trim()}'`).join(', ')})`;
};

let marketSelectionSchema: Record<string, ExtendedFieldConfig> = {
  duration: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Duration",
      options: [
      ],
      group: "row1",
    },
  },
  location: {
    validation: z.string(),
    metadata: {
      type: "select",
      label: "Location",
      options: [
      ],
      group: "row1",
    },
  },
};

const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLiftDashboard: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";

  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>();
  const [activeTab, setActiveTab] = useState<string>("pre-test");
  const [bestMarkets, setBestMarkets] = useState<TableRecord[]>([]); // State for BestMarkets records
  const [bestMarketsColumns, setBestMarketsColumns] = useState<TableColumn[]>([]); // State for BestMarkets columns
  const [detailedResults, setDetailedResults] = useState<TableRecord[]>([]); // State for DetailedResults records
  const [detailedResultsColumns, setDetailedResultsColumns] = useState<TableColumn[]>([]); // State for DetailedResults columns
  const [powerCurves, setPowerCurves] = useState<TableRecord[]>([]); // State for PowerCurves records
  const [powerCurvesColumns, setPowerCurvesColumns] = useState<TableColumn[]>([]); // State for PowerCurves columns
  const [geoLiftObsLabel, setGeoLiftObsLabel] = useState<string[]>();
  const [treatmentValue, setTreatmentValue] = useState();
  const [includeMarket, setIncludeMarket] = useState<string>("");

  const [geoLiftPostObsLabel, setGeoLiftPostObsLabel] = useState<string[]>();
  const [postTreatmentValue, setPostTreatmentValue] = useState();

  const [attLabels, setATTLabels] = useState<string[]>();
  const [estimatedValue, setEstimatedValue] = useState();

  const [powerPlotData, setPowerPlotData] = useState<any>();

  const [powerWellPlotData, setPowerWellPlotData] = useState<any>();

  const [isLoading, setLoading] = useState(false);

  const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(new Set());
  const [outputJson, setOutputJson] = useState<any>(null);
  const [lineData, setLineData] = useState<any[]>([]);
  const [chartKey, setChartKey] = useState<number>(0);
  const [allLineData, setAllLineData] = useState<LineData[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [dates, setDates] = useState<string[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);

  const [jobConfig, setJobConfig] = useState<any>({});
  const [plotDuration, setPlotDuration] = useState<number | undefined>(undefined);

  const [loadingMarketId, setLoadingMarketId] = useState<string | null>(null);

  const [bestMarketsRecords, setBestMarketsRecords] = useState<any[]>([]);

  const [liftPercentage, setLiftPercentage] = useState<number | null>(null);
  const [averageAtt, setAverageAtt] = useState<number>();

  const [incremental, setIncremental] = useState<number | null>(null);
  const [adsSpent, setAdsSpent] = useState<number | null>(null);
  const [confidence, setConfidence] = useState<number | null>(null);
  const [roas, setROAS] = useState<number | null>(null);
  const [salesWithoutCampaignData, setSalesWithoutCampaignData] = useState<number[] | null>(null);
  const [salesWithCampaignData, setSalesWithCampaignData] = useState<number[] | null>(null);
  const [timeLabels, setTimeLabels] = useState<string[] | null>(null);
  const [showChart, setShowChart] = useState(false);

  const [locationSearchTerm, setLocationSearchTerm] = useState("");
  const [selectedLocations, setSelectedLocations] = useState<Set<string>>(new Set());

  const [postTestStartDuration, setPostTestStartDuration] = useState<number>(0);

  const [selectedLocation, setSelectedLocation] = useState<string>("");
  const [testLocationOptions, setTestLocationOptions] = useState<string[]>([]);

  const [durationType, setDurationType] = useState<string>('days');

  const [postTestMarketSchema, setPostTestMarketSchema] = useState<Record<string, ExtendedFieldConfig>>(() => ({
    startDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "Start Duration must be a non-negative integer. Please enter a valid number."),
      metadata: {
        type: "text",
        label: `Campaign Start ${durationType === 'weeks' ? 'Week' : 'Day'}`,
        group: "row1",
        defaultValue: "0",
      },
    },
    endDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "End Duration must be a non-negative integer. Please enter a valid number."),
      metadata: {
        type: "text",
        label: `Campaign End ${durationType === 'weeks' ? 'Week' : 'Day'}`,
        group: "row1",
        defaultValue: "0",
      },
    },
    geoPostTestFile: {
      validation: z.any().refine((file) => file && file.length > 0, {
        message: "Please upload a valid Geo Lift Post Test CSV file."
      }),
      metadata: {
        type: "file",
        label: "Geo Lift Post Test CSV",
        group: "row2",
        accepts: ".csv",
        showFileData: false
      },
    },
    adSpendColumn: {
      validation: z.string().refine(val => val !== 'none' && val.length > 0, "Please select a valid Ad Spend Column from the dropdown."),
      metadata: {
        type: "select",
        label: "Ad Spend Column",
        selectedOptions: [],
        options: [],
        dynamicOptions: {
          field: "geoPostTestFile",
        },
        dynamicOoptionDefault: "Empty",
        group: "row2",
        fieldInfo: "Select the column containing ad spend data for your campaign. This will be used to measure the impact of your campaign on ad spend.",
      },
    },
    adsSpent: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "Total Ad Spend must be a non-negative integer. Please enter a valid amount."),
      metadata: {
        type: "text",
        label: "Total Ad Spend",
        group: "row2",
        defaultValue: "0",
        formatter: {
          type: "currency",
          decimalScale: 2
        },
        showIf: [
          {
            field: "adSpendColumn",
            value: "undefined",
            validation: z.any()
          }
        ]
      },
    },
    location: {
      validation: z.string(),
      metadata: {
        type: "select",
        label: "Best Synthetic Control",
        options: [],
        group: "row4",
        defaultValue: "",
      },
    },
  }));

  const [aggregatedResultsSchema, setAggregatedResultsSchema] = useState<Record<string, ExtendedFieldConfig>>(() => ({
    startDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "Start Duration must be a non-negative integer. Please enter a valid number."),
      metadata: {
        type: "text",
        label: `Campaign Start ${durationType === 'weeks' ? 'Week' : 'Day'}`,
        group: "row1",
        defaultValue: "0",
      },
    },
    endDuration: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "End Duration must be a non-negative integer. Please enter a valid number."),
      metadata: {
        type: "text",
        label: `Campaign End ${durationType === 'weeks' ? 'Week' : 'Day'}`,
        group: "row1",
        defaultValue: "0",
      },
    },
    geoPostTestFile: {
      validation: z.any().refine((file) => file && file.length > 0, {
        message: "Please upload a valid Geo Lift Post Test CSV file."
      }),
      metadata: {
        type: "file",
        label: "Geo Lift Post Test CSV",
        group: "row2",
        accepts: ".csv",
        showFileData: false
      },
    },
    adSpendColumn: {
      validation: z.string().refine(val => val !== 'none' && val.length > 0, "Please select a valid Ad Spend Column from the dropdown."),
      metadata: {
        type: "select",
        label: "Ad Spend Column",
        selectedOptions: [],
        options: [],
        dynamicOptions: {
          field: "geoPostTestFile",
        },
        dynamicOoptionDefault: "Empty",
        group: "row2",
        fieldInfo: "Select the column containing ad spend data for your campaign. This will be used to measure the impact of your campaign on ad spend.",
      },
    },
    adsSpent: {
      validation: z.string().refine((val) => {
        const num = Number(val);
        return Number.isInteger(num) && num >= 0;
      }, "Total Ad Spend must be a non-negative integer. Please enter a valid amount."),
      metadata: {
        type: "text",
        label: "Total Ad Spend",
        group: "row2",
        defaultValue: "0",
        formatter: {
          type: "currency",
          decimalScale: 2
        },
        showIf: [
          {
            field: "adSpendColumn",
            value: "undefined",
            validation: z.any()
          }
        ]
      },
    },
  }));

  const [salesWithCampaign, setSalesWithCampaign] = useState<number | null>(null);
  const [salesWithoutCampaign, setSalesWithoutCampaign] = useState<number | null>(null);

  const [showChartLabels, setShowChartLabels] = useState(false);

  const handleLocationSelection = (location: string, allLineDataParam?: LineData[]) => {
    if (allLineDataParam === undefined) {
      allLineDataParam = allLineData;
    }
    // Parse the location string to get the markets
    const markets = parseLocationString(location);
    
    // Clear existing line data first
    setLineData([]);
    
    // Create a new Set with all the markets from this location group
    const newSelectedMarkets = new Set(
      allLineDataParam.map(item => item.name).filter(item => markets.includes(item.toLowerCase().replaceAll(",", "")))
    );
    setSelectedMarkets(newSelectedMarkets);
    setSelectedLocations(new Set([location]));
    
    // Update line data with filtered data and force chart refresh
    const filteredData = allLineDataParam.filter(item => 
      newSelectedMarkets.has(item.name)
    );
    setLineData(cloneDeep(filteredData));
    setChartKey(prev => prev + 1);  // Force chart refresh

    // Find the matching market record and update power curves and best markets
    const matchingMarket = bestMarketsRecords.find((record: any) => {
      const cleanedLocation = record.location
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("'", "")
        .trim();
      return cleanedLocation === location;
    });

    if (matchingMarket) {
      // Trigger the same update flow as onSubmit
      onSubmit(matchingMarket);
    }
  };

  const handleSelectAll = () => {
    const allMarkets = new Set(
      allLineData.map(item => item.name)
    );
    setSelectedMarkets(allMarkets);
    setSelectedLocations(new Set(filteredLocations));
    
    // Reset and update line data
    setLineData([]);
    setLineData(cloneDeep(allLineData));
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleClearAllFromDashboard = () => {
    setSelectedMarkets(new Set());
    setLineData([]);
  };

  const handleSelectAllLocations = () => {
    if (filteredLocations.length > 0) {
      const firstLocation = filteredLocations[0];
      const markets = parseLocationString(firstLocation);
      setSelectedMarkets(new Set(markets));
      setSelectedLocations(new Set([firstLocation]));
    }
  };

  const handleClearAllLocations = () => {
    setSelectedLocations(new Set());
    handleClearAllFromDashboard();
  };

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const includeMarketValue = response.data.modelParameterConfig.parameters.includeMarket;
      const durationTypeValue = response.data.modelParameterConfig.parameters.durationType || 'days';
      setIncludeMarket(includeMarketValue);
      setDurationType(durationTypeValue);
    });
  }, [id]);

  // Update schema labels when durationType changes
  useEffect(() => {
    setPostTestMarketSchema(prev => ({
      ...prev,
      startDuration: {
        ...prev.startDuration,
        metadata: {
          ...prev.startDuration.metadata,
          label: `Campaign Start ${durationType === 'weeks' ? 'Week' : 'Day'}`
        }
      },
      endDuration: {
        ...prev.endDuration,
        metadata: {
          ...prev.endDuration.metadata,
          label: `Campaign End ${durationType === 'weeks' ? 'Week' : 'Day'}`
        }
      }
    }));
    setAggregatedResultsSchema(prev => ({
      ...prev,
      startDuration: {
        ...prev.startDuration,
        metadata: {
          ...prev.startDuration.metadata,
          label: `Campaign Start ${durationType === 'weeks' ? 'Week' : 'Day'}`
        }
      },
      endDuration: {
        ...prev.endDuration,
        metadata: {
          ...prev.endDuration.metadata,
          label: `Campaign End ${durationType === 'weeks' ? 'Week' : 'Day'}`
        }
      }
    }));
  }, [durationType]);

  const filteredLocations = bestMarkets
    .map(market => market.location)
    .filter((location, index, self) => self.indexOf(location) === index) // Get unique locations
    .filter(location => location.toLowerCase().includes(locationSearchTerm.toLowerCase()));

  useEffect(() => {
    if (bestMarketsRecords.length > 0) {
      const formattedBestMarketsRecords = bestMarketsRecords
        .slice(0, 10)  // Limit to 10 records
        .map((record: any, index: number) => ({
          ...record,
          rank: index + 1,  // Sequential rank
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
          actions: (
            <button
              onClick={() => onSubmit(record)}
              className="btn btn-primary mt-2 w-40 relative"
              disabled={loadingMarketId === record.ID}
            >
              <div className="flex items-center justify-center space-x-2">
                {loadingMarketId === record.ID && (
                  <div className="loading loading-spinner loading-xs"></div>
                )}
                <span>Generate Test Control</span>
              </div>
            </button>
          )
        }));
      setBestMarkets(formattedBestMarketsRecords);
    }
  }, [bestMarketsRecords, loadingMarketId]);

  useEffect(() => {
    if (bestMarkets.length > 0) {
      const uniqueLocations = new Set(bestMarkets.map(market => market.location));
      setSelectedLocations(uniqueLocations);
    }
  }, [bestMarkets]);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const includeMarketValue = response.data.modelParameterConfig.parameters.includeMarket;
      setIncludeMarket(includeMarketValue);
    });
  }, [id]);

  useEffect(() => {
    if (bestMarkets && bestMarkets.length > 0) {
      // Get unique locations and durations from bestMarkets
      const locations = Array.from(new Set(bestMarkets.map((record: any) => record.location)))
        .map(loc => loc.replaceAll("(", "").replaceAll(")", "").replaceAll("'", ""));
      const durations = Array.from(new Set(bestMarkets.map((record: any) => record.duration)));

      // Update the postTestMarketSchema with new options
      setPostTestMarketSchema(prevSchema => {
        console.log("prevSchema", prevSchema)
        return ({
          ...prevSchema,
          location: {
            ...prevSchema.location,
            metadata: {
              ...prevSchema.location.metadata,
              options: [{ label: locations[0], value: locations[0] }],
              defaultValue: locations[0]
            }
          },
        });
      });
    }
  }, [bestMarkets]);

  const [isGetResultsLoading, setIsGetResultsLoading] = useState(false);
  const [isCalculateAggregateLiftLoading, setIsCalculateAggregateLiftLoading] = useState(false);

  const onSubmit = async (record: any) => {
    
    setLoadingMarketId(record.ID);
    const marketSelectionPayload = {
      marketSelectionId: record.ID,
      marketName: selectedLocation,
      jobConfigId: id,
      jobConfig: jobConfig,
      tenantId: getTenantId()
    }
    axiosInstance.post(GEO_LIFT_MARKET_SELECTION, marketSelectionPayload).then((response) => {
      let responseData = response.data;

      // Check if the response data is a string and parse it as JSON if necessary
      if (typeof responseData === 'string') {
        try {
          responseData = JSON.parse(responseData);
        } catch (error) {
          console.error('Failed to parse response data as JSON:', error);
        }
      }

      if (responseData) {
        const geoLiftPlot = responseData.lift_plot;
        const powerPlot = responseData.power_plot;
        const liftPercentage = responseData.lift_percentage;
        
        

        // Update power plot data with the current selected location
        const updatedPowerPlot = {
          ...powerPlot,
          location: selectedLocation // Update location to match selected market
        };
        setPowerPlotData(updatedPowerPlot);

        const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
        setGeoLiftObsLabel(labels);
        const c_obsData = Object.values(geoLiftPlot.c_obs);
        const t_obsData = Object.values(geoLiftPlot.t_obs);

        setPlotDuration((t_obsData.length - record.duration) as any);

        const dataSets = [
          {
            lineType: "dashed",
            name: "Synthetic Control", // Name for legend
            data: c_obsData, // y-axis values
            borderColor: "#d6279a", // Line color (blue for example)
            backgroundColor: "rgba(59, 130, 246, 0.0)" // Background fill color (light blue)
          },
          {
            name: `Test Location (${selectedLocation})`, // Name for legend with selected location
            data: t_obsData, // y-axis values
            borderColor: "#006400", // Line color (blue for example)
            backgroundColor: "rgba(59, 2, 246, 0.0)" // Background fill color (light blue)
          },
        ];

        setTreatmentValue(dataSets as any);

        setLiftPercentage(liftPercentage);

        setLoading(false);
        setLoadingMarketId(null);
      }
    }).catch((e) => {
      
      setLoadingMarketId(null);
      setLoading(false);
    });

    axiosInstance.post(GEO_LIFT_POWER_PLOT_API, marketSelectionPayload).then((response) => {
      let responseData = response.data;

      // Check if the response data is a string and parse it as JSON if necessary
      if (typeof responseData === 'string') {
        try {
          responseData = JSON.parse(responseData);
        } catch (error) {
          console.error('Failed to parse response data as JSON:', error);
        }
      }

      if (responseData) {
        // Update power well plot data with the current selected location
        const updatedPowerWellPlot = {
          ...responseData,
          PowerCurves: responseData.PowerCurves?.map((curve: any) => ({
            ...curve,
            location: selectedLocation // Update location to match selected market
          }))
        };
        setPowerWellPlotData(updatedPowerWellPlot);

        setLoading(false);
      }
    }).catch((e) => {
      
      setLoadingMarketId(null);
      setLoading(false);
    });
  };

  const onAggregatedResultsSubmit = async (data: Record<string, any>) => {
    try {
      let totalAdSpend = parseFloat(data.adsSpent);
      if (isNaN(totalAdSpend)) {
        throw new Error('Total Ad Spend is required and must be a valid number.');
      }

      if (data.adSpendColumn && data.adSpendColumn !== 'undefined' && data.geoPostTestFile[0]) {
        await new Promise<void>((resolve) => {
          Papa.parse(data.geoPostTestFile[0], {
            complete: (results: any) => {
              const adSpendColumn = data.adSpendColumn;
        
              totalAdSpend = results.data.reduce((sum: number, row: any) => {
                const value = parseFloat(row[adSpendColumn]) || 0;
                return sum + value;
              }, 0);
              resolve();
            },
            header: true,
            skipEmptyLines: true
          });
        });
      }

      setIsCalculateAggregateLiftLoading(true);
      const formData = new FormData();
      formData.append("tenantId", jobConfig?.tenantId);
      formData.append("jobConfigId", id as string);
      formData.append("confidenceThreshold", "50"); // Default confidence threshold
      formData.append("totalAdSpend", totalAdSpend.toString());
      Object.keys(data).forEach(key => {
        if (key === 'geoPostTestFile' && data[key][0]) {
          formData.append('csv_file', data[key][0]);
        } else {
          formData.append(key, data[key]);
        }
      });

      const response = await axiosInstance.post(GEO_LIFT_AGGREGATE_LIFT_API, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      
      if (response.data) {
        
        let responseData = response.data;

        // Check if the response data is a string and parse it as JSON if necessary
        if (typeof responseData === 'string') {
          try {
            responseData = JSON.parse(responseData);
          } catch (error) {
            console.error('Failed to parse response data as JSON:', error);
          }
        }

        if (responseData) {
          const { lift, roas, graph } = responseData;
          
          // Update state with lift results
          setLiftPercentage(lift.totalLiftPercentage);
          setSalesWithCampaign(lift.totalSalesWithCampaign);
          setSalesWithoutCampaign(lift.totalSalesWithoutCampaign);
          
          // Update state with ROAS results
          setAdsSpent(roas.totalAdSpendForSyntheticMarkets);
          setIncremental(roas.totalIncrementSales);
          setROAS(roas.totalROAS);

          // Update state with time series data
          if (graph) {
            const { salesWithCampaignData, salesWithoutCampaignData, labels } = graph;
            const swc = salesWithCampaignData || [];
            const swoc = salesWithoutCampaignData || [];
            const generatedLabels = (labels && labels.length > 0) ? labels : Array.from({ length: swc.length }, (_, i) => `${i + 1}`);
            setSalesWithCampaignData(swc);
            setSalesWithoutCampaignData(swoc);
            setTimeLabels(generatedLabels);
            console.log('Time Series Data:', {
              salesWithCampaign: swc,
              salesWithoutCampaign: swoc,
              labels: generatedLabels
            });
            // Increase delay to 500ms to ensure the container dimensions are set
            setShowChart(false);
            setTimeout(() => setShowChart(true), 500);
          }

          // Display the results
          setActiveTab('aggregated-results');
        }
      }
    } catch (error) {
      console.error('Error calculating aggregate lift percentage:', error);
      // Handle error appropriately
    } finally {
      setIsCalculateAggregateLiftLoading(false);
    }
  };

  const onGeoPostTest = async (data: Record<string, any>) => {
    setIsGetResultsLoading(true);

    if (data.geoPostTestFile[0]) {
      Papa.parse(data.geoPostTestFile[0], {
        header: true, // Treat the first row as headers
        complete: (result) => {
          const parsedData = result.data;
          const dataByLocation: { [location: string]: { date: string; value: number }[] } = {};

          // Parse the data and group by location
          parsedData.forEach((row: any) => {
            const { location, Y, date } = row;
            if (!dataByLocation[location]) {
              dataByLocation[location] = [];
            }
            dataByLocation[location].push({ date, value: Number(Y) });
          });

          // Extract unique locations
          const uniqueLocations = Object.keys(dataByLocation).map((location) => ({
            label: location
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
            value: location,
          }));

          // Extract unique dates from one of the locations
          const sampleLocation = Object.keys(dataByLocation)[0];
          const dateLabels = dataByLocation[sampleLocation].map((item) => item.date);
          setDates(dateLabels);

          // Create the line data for each location
          const formattedData: LineData[] = Object.keys(dataByLocation).map((location) => {
            const dataPoints = dataByLocation[location].map((item) => item.value);

            return {
              name: location,
              data: dataPoints,
              borderColor: getRandomColor(), // Assign random colors for each line
              backgroundColor: getRandomColorWithOpacity(0.3), // Background color with opacity
            };
          });

          setAllLineData(formattedData);
          setLineData(formattedData);
          setSelectedMarkets(new Set(formattedData.map(item => item.name)));

          handleClearAllLocations();
          handleLocationSelection(data.location, formattedData);
        },
      });
    }


    const geoLiftPostTestFormData = new FormData();

    if (data.location === "") {
      const locations = Array.from(new Set(bestMarkets.map((record: any) => record.location)))
        .map(loc => loc.replaceAll("(", "").replaceAll(")", "").replaceAll("'", ""));
      data.location = locations[0]; // Use the currently selected location instead of first location
    }

    const matchingMarket = bestMarkets.find((record: any) => {
      const cleanedLocation = record.location
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("'", "")
        .trim();
      return cleanedLocation === data.location;
    });

    const marketId = matchingMarket ? matchingMarket.ID : '0';
    setIsGetResultsLoading(true);

    // Calculate ad spend from CSV
    const calculateAdSpend = async () => {
      return new Promise<number>((resolve) => {
        Papa.parse(data.geoPostTestFile[0], {
          complete: (results) => {
            const rows = results.data as string[][];
            const headers = rows[0];
            
            // Find the index of the ad spend column and location column
            const adSpendColumnIndex = headers.findIndex(h => h.trim() === data.adSpendColumn);
            const locationColumnIndex = headers.findIndex(h => h.toLowerCase().includes('location') || h.toLowerCase().includes('market'));

            const uniqueLocationsSet = new Set<string>();
            rows.slice(1).forEach(row => {
              const location = row[locationColumnIndex]?.trim();
              if (location) {
                uniqueLocationsSet.add(location);
              }
            });

            const uniqueLocationsLength = uniqueLocationsSet.size;

            if (data.adSpendColumn === "none" || data.adSpendColumn === "undefined") {
              resolve((data.adsSpent / uniqueLocationsLength) * data.location.split(',').length);
              return;
            }

            // Get the synthetic control locations from the form input
            const syntheticControlLocations = data.location
              .split(',')
              .map((loc: string) => loc.trim().toLowerCase());
            
            // Filter rows by synthetic control locations and sum ad spend
            const totalAdSpend = rows.slice(1) // Skip header row
              .filter(row => {
                if (!row[locationColumnIndex]) return false;
                const location = row[locationColumnIndex].trim().replaceAll(',', '').toLowerCase();
                return syntheticControlLocations.includes(location);
              })
              .reduce((sum, row) => {
                const value = parseFloat(row[adSpendColumnIndex]) || 0;
                return sum + value;
              }, 0);

            resolve(totalAdSpend);
          },
          header: false,
          skipEmptyLines: true
        });
      });
    };

    const totalAdSpend = await calculateAdSpend();
    setAdsSpent(totalAdSpend);

    geoLiftPostTestFormData.append("marketSelectionId", marketId);
    geoLiftPostTestFormData.append("startDuration", data.startDuration);
    geoLiftPostTestFormData.append("endDuration", data.endDuration);
    geoLiftPostTestFormData.append("marketsList", data.location);
    geoLiftPostTestFormData.append("csv_file", data.geoPostTestFile[0]);
    geoLiftPostTestFormData.append("jobConfigId", id as string);
    geoLiftPostTestFormData.append("tenantId", getTenantId());
    geoLiftPostTestFormData.append("market_name", selectedLocation);
    geoLiftPostTestFormData.append("adSpendColumn", data.adSpendColumn || "none");
    geoLiftPostTestFormData.append("calculatedAdSpend", totalAdSpend.toString());

    setPostTestStartDuration(Number(data.startDuration));

    try {
      // Send the POST request using Axios
      const geoLiftPostTestResponse = await axiosInstance.post(
        GEO_LIFT_POST_TEST_API,
        geoLiftPostTestFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      let responseData = geoLiftPostTestResponse.data;

      // Check if the response data is a string and parse it as JSON if necessary
      if (typeof responseData === 'string') {
        try {
          responseData = JSON.parse(responseData);
        } catch (error) {
          console.error('Failed to parse response data as JSON:', error);
        }
      }

      if (responseData) {
        setIncremental(responseData.incremental);
        setLiftPercentage(responseData.lift_percentage);
        setSalesWithCampaign(responseData.salesWithCampaign);
        setSalesWithoutCampaign(responseData.salesWithoutCampaign);
        setConfidence((1 - responseData.pvalue) * 100);

        const geoLiftPlot = responseData.lift_plot;

        const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
        setGeoLiftPostObsLabel(labels);
        const c_obsData = Object.values(geoLiftPlot.c_obs);
        const t_obsData = Object.values(geoLiftPlot.t_obs);

        const dataSets = [
          {
            name: "Synthetic Control",
            lineType: "dashed",
            data: c_obsData,
            borderColor: "#d6279a",
            backgroundColor: "rgba(214, 39, 154, 0)"
          },
          {
            name: `Test Location (${selectedLocation})`,
            data: t_obsData,
            borderColor: "#006400",
            backgroundColor: "rgba(0, 100, 0, 0)"
          },
        ];

        setPostTreatmentValue(dataSets as any);

        const attPlot = responseData.att_plot;
        const attLabels = Object.values(attPlot.Time).map((time: any) => time.toString());
        setATTLabels(attLabels);
        const estimateData = Object.values(attPlot.Estimate).map((value: any) =>
          parseFloat(value.toFixed(2))
        );
        const estimatedDataSets = [
          {
            type: "line",
            lineType: "dashed",
            name: "ATT",
            data: estimateData,
            borderColor: "#3b82f6",
            backgroundColor: "rgba(59, 130, 246, 0.0)"
          }
        ];

        setEstimatedValue(estimatedDataSets as any);

        setLiftPercentage(responseData.lift_percentage);
        setAverageAtt(responseData.average_att);
        setIsGetResultsLoading(false);
      }
    } catch (error) {
      console.error("Error in post test API:", error);
      setIsGetResultsLoading(false);
    }
  };

  const validateDurations = (data: Record<string, any>, setError: any): Promise<boolean> => {
    const startDuration = Number(data.startDuration);
    const endDuration = Number(data.endDuration);
    if (endDuration <= startDuration) {
      setError('endDuration', {
        type: 'manual',
        message: 'End Duration must be greater than Start Duration.'
      });
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  };

  const validateAggregatedDurations = async (data: Record<string, any>, setError: any): Promise<boolean> => {
    const startDuration = Number(data.startDuration);
    const endDuration = Number(data.endDuration);
    if (endDuration <= startDuration) {
      setError('endDuration', {
        type: 'manual',
        message: 'End Duration must be greater than Start Duration.'
      });
      return Promise.resolve(false);
    }
    return Promise.resolve(true);
  };

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
        return [
          ...prevBreadcrumbs,
          { name: name, url: `/measurement/geo-lift/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      setJobConfig(response.data);
    });
    axiosInstance.get(`/api/geo-lift/output/${id}`).then((response) => {
      let outputJson = response.data.result;
      if (typeof outputJson === "string") {
        try {
          outputJson = JSON.parse(outputJson);
          console.log("geo lift output response", outputJson);
        } catch (e) {
          console.error("Error parsing JSON:", e);
        }
      }
      
      setOutputJson(outputJson);
      const bestMarketsColumns: TableColumn[] = [
        { key: "rank", label: (
          <span className="flex items-center gap-1">
            Rank
            <InfoIcon info="Ranking of the market based on its performance metrics" />
          </span>
        ), decimal: 0 },
        { key: "formattedLocation", label: (
          <span className="flex items-center gap-1">
            Location
            <InfoIcon info="The control markets represent the locations selected for the augmented synthetic control group, which serve as the performance benchmark." />
          </span>
        ) },
        { key: "Investment", label: (
          <span className="flex items-center gap-1">
            Investment
            <InfoIcon info="The required test investment is determined by multiplying three factors: the cost per incremental conversion (CPIC), total baseline KPI volume in test locations, and the target effect size parameter. This calculation ensures adequate statistical power for the test." />
          </span>
        ), prefix: "$" },
        { key: "duration", label: (
          <span className="flex items-center gap-1">
            Duration
            <InfoIcon info="The test duration specifies the marketing campaign's operational period, measured in either weeks or days." />
          </span>
        ), decimal: 0 },
        { key: "abs_lift_in_perc", label: (
          <span className="flex items-center gap-1">
            Absolute Lift (%)
            <InfoIcon info="Difference between predicted and actual lift values" />
          </span>
        ), decimal: 2, postfix: "%" },
        { key: "AvgATT", label: (
          <span className="flex items-center gap-1">
            Lift Value
            <InfoIcon info="The average lift value measures the aggregate impact between test and synthetic control markets, normalized across campaign duration and number of control markets. For instance, a total lift of 4,667 units over a 15-day campaign with two control markets yields an average daily lift per market of 155.56 units (4,667 ÷ (15 × 2))." />
          </span>
        ) },
        // { key: "EffectSize", label: (
        //   <span className="flex items-center gap-1">
        //     Effect Size
        //     <InfoIcon info="The lift simulation parameter defines the target lift percentage for the augmented synthetic control model. The parameter ranges from 0.0 (simulating no lift) to 0.25 (simulating 25% lift), with 0.05 incremental steps. These increments enable systematic evaluation of model performance across different lift scenarios." />
        //   </span>
        // ) },
        { key: "Power", label: (
          <span className="flex items-center gap-1">
            Power
            <InfoIcon info="To assess the effectiveness of the marketing campaign, we measure its predictive power at each time interval. A prediction is deemed statistically significant if its confidence level is at least equal to the initial confidence threshold. The overall campaign performance metric is derived by averaging these individual time-point power values over the entire campaign duration." />
          </span>
        ), decimal: 0 },
        { key: "actions", label: "Actions" },
      ];

      // Set up the table records for BestMarkets
      const bestMarketsRecords = outputJson.BestMarkets || [];
      
      let currentRank = 1;
      const formattedBestMarketsRecords = bestMarketsRecords
        .slice(0, 10)  // Limit to 10 records
        .map((record: any, index: number) => ({
          ...record,
          rank: index + 1,  // Sequential rank
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
          actions: (
            <button
              onClick={() => onSubmit(record)}
              className="btn btn-primary mt-2 w-40 relative"
              disabled={loadingMarketId === record.ID}
            >
              <div className="flex items-center justify-center space-x-2">
                {loadingMarketId === record.ID && (
                  <div className="loading loading-spinner loading-xs"></div>
                )}
                <span>Generate Test Control</span>
              </div>
            </button>
          )
        }));
      setBestMarkets(formattedBestMarketsRecords);
      setBestMarketsColumns(bestMarketsColumns);

      const uniqueLocations: string[] = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.location))
      );

      const uniqueDuration: number[] = Array.from(
        new Set(bestMarketsRecords.map((record: any) => record.duration))
      );

      marketSelectionSchema.location.metadata.options = uniqueLocations.map((location) => {
        const formattedLabel = location
          .replace(/[()']/g, '')
          .split(',')
          .map(city => city.trim())
          .map(city => city.charAt(0).toUpperCase() + city.slice(1))
          .join(', ');

        return {
          value: location,
          label: formattedLabel
        } as FieldOption;
      });


      marketSelectionSchema.duration.metadata.options = uniqueDuration.map((duration: number) => ({
        value: duration.toString(),
        label: duration.toString()
      }));

      postTestMarketSchema.location.metadata.options = marketSelectionSchema.location.metadata.options;

      // Fix: Set default values as strings
      const firstLocation = uniqueLocations[0] || "";
      
      postTestMarketSchema.location.metadata.defaultValue = firstLocation;

      setRefresh(!refresh);

      // Set up the table columns for DetailedResults
      const detailedResultsColumns: TableColumn[] = [
        { key: "EffectSize", label: "Effect Size" },
        { key: "Investment", label: "Investment", prefix: "$" },
        { key: "ScaledL2Imbalance", label: "Scaled L2 Imbalance" },
        { key: "att_estimator", label: "ATT Estimator" },
        { key: "cpic", label: "CPIC" },
        { key: "detected_lift", label: "Detected Lift" },
        { key: "duration", label: "Duration", decimal: 0 },
        { key: "location", label: "Location" },
        { key: "pvalue", label: "P-Value" },
        { key: "treatment_start", label: "Treatment Start" },
      ];

      // Set up the table records for DetailedResults
      const detailedResultsRecords = outputJson.DetailedResults || [];
      setDetailedResultsColumns(detailedResultsColumns);
      setDetailedResults(detailedResultsRecords);

      // Set up the table columns for PowerCurves
      const powerCurvesColumns: TableColumn[] = [
        // { key: "AvgATT", label: (
        //   <span className="flex items-center gap-1">
        //     Avg. Lift Value
        //     <InfoIcon info="The average lift value measures the aggregate impact between test and synthetic control markets, normalized across campaign duration and number of control markets. For instance, a total lift of 4,667 units over a 15-day campaign with two control markets yields an average daily lift per market of 155.56 units (4,667 ÷ (15 × 2))." />
        //   </span>
        // ) },
        { 
          key: "AvgDetectedLift", 
          label: (
            <span className="flex items-center gap-1">
              Avg Detected Lift (%)
              <InfoIcon info="The percentage lift represents the relative performance difference, calculated by dividing the absolute difference between test market KPI and predicted synthetic control values by the total predicted synthetic control baseline. This normalization allows for comparable measurement of campaign effectiveness." />
            </span>
          ),
          decimal: 2
        },
        // { key: "EffectSize", label: (
        //   <span className="flex items-center gap-1">
        //     Effect Size
        //     <InfoIcon info="The lift simulation parameter defines the target lift percentage for the augmented synthetic control model. The parameter ranges from 0.0 (simulating no lift) to 0.25 (simulating 25% lift), with 0.05 incremental steps. These increments enable systematic evaluation of model performance across different lift scenarios." />
        //   </span>
        // ) },
        { key: "Investment", label: (
          <span className="flex items-center gap-1">
            Investment
            <InfoIcon info="The required test investment is determined by multiplying three factors: the cost per incremental conversion (CPIC), total baseline KPI volume in test locations, and the target effect size parameter. This calculation ensures adequate statistical power for the test." />
          </span>
        ), prefix: "$" },
        { key: "duration", label: (
          <span className="flex items-center gap-1">
            Campaign Operational Period
            <InfoIcon info="The test duration specifies the marketing campaign's operational period, measured in either weeks or days." />
          </span>
        ), decimal: 0 },
        { key: "formattedLocation", label: (
          <span className="flex items-center gap-1">
            Location
            <InfoIcon info="The control markets represent the locations selected for the augmented synthetic control group, which serve as the performance benchmark." />
          </span>
        ) },
        { key: "power", label: (
          <span className="flex items-center gap-1">
            Power
            <InfoIcon info="To assess the effectiveness of the marketing campaign, we measure its predictive power at each time interval. A prediction is deemed statistically significant if its confidence level is at least equal to the initial confidence threshold. The overall campaign performance metric is derived by averaging these individual time-point power values over the entire campaign duration." />
          </span>
        ), decimal: 0 },
      ];

      // Set up the table records for PowerCurves
      const powerCurvesRecords = outputJson.PowerCurves || [];
      
      const formattedPowerCurvesRecords = powerCurvesRecords
        .filter((record: any) => {
          const investment = parseFloat(record.Investment);
          return !isNaN(investment) && investment > 0;
        })
        .slice(0, 10)  // Limit to 10 records
        .map((record: any) => ({
          ...record,
          AvgDetectedLift: `${(record.AvgDetectedLift * 100).toFixed(2)}%`,
          formattedLocation: record.location
            .replaceAll("(", "")
            .replaceAll(")", "")
            .replaceAll("'", "")
            .split(',')
            .map((loc: string, index: number) => (
              <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
                {loc.trim()}<br />
              </span>
            )),
        }));

      setPowerCurvesColumns(powerCurvesColumns);
      setPowerCurves(formattedPowerCurvesRecords);
    });
  }, [id]);

  useEffect(() => {
    if (allLineData.length === 0) return;

    const filteredData = allLineData.filter(item => {
      const marketSelected = selectedMarkets.has(item.name);
      return marketSelected;
    });

    setLineData([]);
    setLineData(cloneDeep(filteredData));
  }, [selectedMarkets, allLineData]);

  const filteredMarkets = allLineData.filter(item => 
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleMarketSelection = (marketName: string) => {
    const newSelectedMarkets = new Set(selectedMarkets);
    if (newSelectedMarkets.has(marketName)) {
      newSelectedMarkets.delete(marketName);
    } else {
      newSelectedMarkets.add(marketName);
    }
    setSelectedMarkets(newSelectedMarkets);
    updateChartData(newSelectedMarkets);
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleSelectAllMarkets = () => {
    const allMarkets = new Set(
      allLineData.map(item => item.name)
    );
    setSelectedMarkets(allMarkets);
    setSelectedLocations(new Set(filteredLocations));
    
    // Reset and update line data
    setLineData([]);
    setLineData(cloneDeep(allLineData));
    setChartKey(prev => prev + 1);  // Force chart refresh
  };

  const handleClearAll = () => {
    setSelectedMarkets(new Set());
    setLineData([]);
  };

  const updateChartData = (selectedMarkets: Set<string>) => {
    const filteredData = allLineData.filter(item => selectedMarkets.has(item.name));
    setLineData(filteredData);
  };

  useEffect(() => {
    if (!outputJson) return;

    // Extract unique locations from the response keys
    const locations = Object.keys(outputJson)
      .filter(key => key.includes("/BestMarkets"))
      .map(key => key.split("/")[0]);
    
    setTestLocationOptions(locations);
    if (locations.length > 0) {
      setSelectedLocation(locations[0]); // Set first location as default
    }
  }, [outputJson]);

  // Filter results based on selected location
  const getFilteredResults = () => {
    if (!outputJson || !selectedLocation) return { bestMarkets: [], powerCurves: [] };
    
    return {
      bestMarkets: outputJson[`${selectedLocation}/BestMarkets`] || [],
      powerCurves: outputJson[`${selectedLocation}/PowerCurves`] || []
    };
  };

  useEffect(() => {
    if (!selectedLocation) return;

    const { bestMarkets, powerCurves } = getFilteredResults();
    
    // Format and set best markets data
    const formattedBestMarkets = bestMarkets
      .slice(0, 10)  // Limit to 10 records
      .map((record: any, index: number) => ({
        ...record,
        rank: index + 1,  // Sequential rank
        formattedLocation: record.location
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("'", "")
          .split(',')
          .map((loc: string, index: number) => (
            <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
              {loc.trim()}<br />
            </span>
          )),
        actions: (
          <button
            onClick={() => onSubmit(record)}
            className="btn btn-primary mt-2 w-40 relative"
            disabled={loadingMarketId === record.ID}
          >
            <div className="flex items-center justify-center space-x-2">
              {loadingMarketId === record.ID && (
                <div className="loading loading-spinner loading-xs"></div>
              )}
              <span>Generate Test Control</span>
            </div>
          </button>
        )
      }));
    setBestMarketsRecords(formattedBestMarkets);

    // Format and set power curves data
    const formattedPowerCurves = powerCurves
      .filter((record: any) => {
        const investment = parseFloat(record.Investment);
        return !isNaN(investment) && investment > 0;
      })
      .slice(0, 10)  // Limit to 10 records
      .map((record: any) => ({
        ...record,
        AvgDetectedLift: `${(record.AvgDetectedLift * 100).toFixed(2)}%`,
        formattedLocation: record.location
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("'", "")
          .split(',')
          .map((loc: string, index: number) => (
            <span key={index} style={{ backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4' }}>
              {loc.trim()}<br />
            </span>
          )),
      }));
    setPowerCurves(formattedPowerCurves);

    // Find the matching market record for the selected location and trigger onSubmit
    if (formattedBestMarkets.length > 0) {
      const matchingMarket = formattedBestMarkets.find((record: any) => {
        const cleanedLocation = record.location
          .replaceAll("(", "")
          .replaceAll(")", "")
          .replaceAll("'", "")
          .trim();
        return cleanedLocation === selectedLocation;
      });
      
      if (matchingMarket) {
        onSubmit(matchingMarket);
      }
    }
  }, [selectedLocation, outputJson]);

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between items-center mb-6">
        <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.url}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="tabs tabs-boxed mb-4">
          <a 
            className={`tab text-lg font-medium ${activeTab === "pre-test" ? "tab-active" : ""}`} 
            onClick={() => handleTabClick("pre-test")}
          >
            Synthetic Control
          </a>
          <a 
            className={`tab text-lg font-medium ${activeTab === "post-test" ? "tab-active" : ""}`}
            onClick={() => handleTabClick("post-test")}
          >
            Geo Study Results
          </a>
          <a 
            className={`tab text-lg font-medium ${activeTab === "aggregated-results" ? "tab-active" : ""}`}
            onClick={() => handleTabClick("aggregated-results")}
          >
            Geo Study Aggregated Results
          </a>
        </div>
        
        <div id="pre-test" className={`tab-content ${activeTab === "pre-test" ? "block" : "hidden"}`}>
          {testLocationOptions.length > 0 && (
            <div className="flex justify-center items-center mb-4">
              <label className="mr-2 font-bold text-gray-900">
                {jobConfig?.modelParameterConfig?.parameters?.includeMarket ? "Test Markets:" : "Test Markets:"}
              </label>
              <div className="relative">
                <select
                  className="appearance-none border-[0.5px] border-gray-200 rounded-md pl-3 pr-7 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-400"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  {testLocationOptions.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>
          )}
          {bestMarkets && bestMarkets.length > 0 && (
            <Maximize title="Best Markets">
              <div className="w-full pt-4 max-h-80 overflow-y-auto">
                <Table columns={bestMarketsColumns} records={bestMarkets} size="medium" />
              </div>
            </Maximize>
          )}

          {geoLiftObsLabel && treatmentValue && (
            <Maximize title={`Test vs Synthetic Control Graph (Lift: ${liftPercentage?.toFixed(2)}%)`}>
              <LineChart
                key={`control-treatment-${chartKey}`}
                labels={geoLiftObsLabel as any}
                dataSets={treatmentValue as any}
                xAxisTitle="Time"
                yAxisTitle={`Test Location`}
                highlightAreaFrom={plotDuration}
                verticalLineAt={plotDuration}
              />
            </Maximize>
          )}

          {/* {powerPlotData && (
            <Maximize title="Power Plot">
              <PowerCurveChart
                investmentData={Object.values(powerPlotData.Investment)}
                effectSizeData={Object.values(powerPlotData.EffectSize)}
                powerData={Object.values(powerPlotData.Power)}
                height="500px"
              />
            </Maximize>
          )} */}

          {powerWellPlotData && (
            <Maximize title="Geo Lift Power Curve">
              <GeoLiftPowerCurve data={powerWellPlotData} />
            </Maximize>
          )}
        </div>

        <div id="post-test" className={`tab-content ${activeTab === "post-test" ? "block" : "hidden"}`}>
          {testLocationOptions.length > 0 && (
            <div className="flex items-center justify-center mb-4">
              <label className="mr-2 font-bold text-gray-900">
                {jobConfig?.modelParameterConfig?.parameters?.includeMarket ? "Test Markets:" : "Test Markets:"}
              </label>
              <div className="relative">
                <select
                  className="appearance-none border-[0.5px] border-gray-200 rounded-md pl-3 pr-7 py-1.5 focus:outline-none focus:ring-1 focus:ring-blue-400"
                  value={selectedLocation}
                  onChange={(e) => setSelectedLocation(e.target.value)}
                >
                  {testLocationOptions.map((location) => (
                    <option key={location} value={location}>
                      {location}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
                  <svg className="h-4 w-4 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </div>
            </div>
          )}
          {bestMarkets && bestMarkets.length > 0 && postTestMarketSchema && (
            <div>
              <DynamicForm
                formName="Create Job Form"
                formSchema={postTestMarketSchema}
                onSubmit={onGeoPostTest}
                validationCallback={validateDurations}
                onSubmitText={"Get Results"}
                isLoading={isGetResultsLoading}
              />
            </div>
          )}

          <div className="my-8">
            <div className="border-t border-gray-200"></div>
          </div>

          {/* {dates && dates.length > 0 && allLineData.length > 0 && (
            <Maximize title="Geo Market Trend">
              <div className="min-h-[400px]">
                <div className="flex justify-end mb-4 gap-2">
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Markets ({selectedMarkets.size}/{allLineData.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAllMarkets();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAllFromDashboard();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search markets..."
                          className="input input-xs input-bordered w-full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredMarkets.length > 0 ? (
                          filteredMarkets.map((item) => (
                            <div key={item.name} className="form-control">
                              <label className="label cursor-pointer justify-start gap-2">
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-xs"
                                  checked={selectedMarkets.has(item.name)}
                                  onChange={() => handleMarketSelection(item.name)}
                                />
                                <span className="label-text">{item.name}</span>
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No markets found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Locations ({selectedLocations.size}/{filteredLocations.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAllLocations();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAllLocations();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search locations..."
                          className="input input-xs input-bordered w-full"
                          value={locationSearchTerm}
                          onChange={(e) => setLocationSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredLocations.length > 0 ? (
                          filteredLocations.map((location) => {
                            const markets = parseLocationString(location);
                            return (
                              <div key={location} className="form-control">
                                <label className="label cursor-pointer justify-start gap-2">
                                  <input
                                    type="radio"
                                    name="location-radio"
                                    className="radio radio-xs"
                                    checked={selectedLocations.has(location)}
                                    onChange={() => handleLocationSelection(location)}
                                  />
                                  <div className="flex flex-col">
                                    <span className="label-text font-medium">Location Group {filteredLocations.indexOf(location) + 1}</span>
                                    <div>
                                      {markets.map((loc, index) => (
                                        <span 
                                          key={index} 
                                          className="block text-xs" 
                                          style={{ 
                                            backgroundColor: index % 2 === 0 ? '#c6efce' : '#f6f4e4',
                                            padding: '2px 4px',
                                            marginBottom: '1px'
                                          }}
                                        >
                                          {loc}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                </label>
                              </div>
                            );
                          })
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No locations found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                
                {selectedMarkets.size === 0 ? (
                  <div className="flex items-center justify-center h-[400px] text-gray-500">
                    Please select at least one market to view the chart
                  </div>
                ) : (
                  <LineChart
                    key={chartKey}
                    labels={dates}
                    dataSets={lineData}
                    xAxisTitle="Date"
                    yAxisTitle="Value"
                    highlightAreaFrom={postTestStartDuration}
                    showSymbols={true}
                    height="400px"
                  />
                )}
              </div>
            </Maximize>
          )} */}

          {incremental !== null && adsSpent !== null && roas !== null && (
            <div className="mt-8">
              <Maximize title="Lift Results">
                <div className="flex justify-between px-4 py-4 space-x-4">
                  <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg w-1/2">
                    <span className="font-medium text-blue-600">
                      Lift Percentage: {liftPercentage}%</span>
                  </div>
                  <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg w-1/2">
                    <span className="font-medium text-blue-600">
                      Incremental Sales: {Math.round(incremental).toLocaleString()}</span>
                  </div>
                </div>
                <GeoStudyResults
                  incrementalSales={incremental ?? 0}
                  adSpend={adsSpent ?? 0}
                  roas={roas ?? 0}
                />
              </Maximize>
            </div>
          )}

          {salesWithoutCampaign !== null && salesWithCampaign !== null && liftPercentage !== null && incremental !== null && (
            <div className="mt-4">
              <Maximize title="Lift Results">
                <div className="px-4 py-4">
                  <div className="flex justify-end mb-2">
                    <button
                      onClick={() => setShowChartLabels(!showChartLabels)}
                      className="px-3 py-1 text-sm text-[#143969] border border-[#143969] rounded hover:bg-[#143969] hover:text-white transition-colors"
                    >
                      {showChartLabels ? "Hide Labels" : "Show Labels"}
                    </button>
                  </div>
                  <div className="grid grid-cols-4 gap-4 mb-4">
                    <div className="text-center py-2 bg-blue-50 rounded-lg">
                      <span className="font-medium text-blue-600">
                        Lift Percentage: {liftPercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="text-center py-2 bg-blue-50 rounded-lg">
                      <span className="font-medium text-blue-600">
                        Incrementality Value: {Math.round(incremental).toLocaleString()}
                      </span>
                    </div>
                    <div className="text-center py-2 bg-blue-50 rounded-lg">
                      <span className="font-medium text-blue-600">
                        ROAS: {adsSpent ? (incremental / adsSpent).toFixed(2) + 'x' : '-'}
                      </span>
                    </div>
                    <div className="text-center py-2 bg-blue-50 rounded-lg">
                      <span className="font-medium text-blue-600">
                        Confidence: {confidence ? confidence.toFixed(1) + '%' : '-'}
                      </span>
                    </div>
                  </div>
                  <MultiBarLineChartECharts
                    labels={["Sales Without Campaign", "Sales With Campaign"]}
                    barDatasets={[
                      {
                        name: "Campaign Performance",
                        values: salesWithoutCampaign && salesWithCampaign ? [salesWithoutCampaign, salesWithCampaign] : [0, 0],
                        color: "#143969"
                      }
                    ]}
                    lineDatasets={[]}
                    showLabels={showChartLabels}
                    showMinLine={true}
                  />
                </div>
              </Maximize>
            </div>
          )}

          {geoLiftPostObsLabel && postTreatmentValue && (
            <Maximize title="Test Vs Synthetic Control">
              <div>
                <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg">
                  <span className="font-medium text-blue-600">
                    Lift Percentage: {liftPercentage ? `${liftPercentage.toFixed(2)}%` : '-'}
                  </span>
                </div>
                <LineChart
                  key={`control-treatment-${chartKey}`}
                  labels={geoLiftPostObsLabel as any}
                  dataSets={postTreatmentValue as any}
                  xAxisTitle="Time"
                  yAxisTitle="Control Observations (c_obs)"
                  highlightAreaFrom={postTestStartDuration}
                  verticalLineAt={postTestStartDuration}
                  showSymbols={true}
                  height="500px"
                />
              </div>
            </Maximize>
          )}

          {attLabels && estimatedValue && (
            <Maximize title="Lift Trend">
              <div>
                <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg">
                  <span className="font-medium text-blue-600">
                    Average Lift Value: {averageAtt?.toFixed(2)}
                  </span>
                </div>
                <LineChart
                  key={`treatment-effect-${chartKey}`}
                  labels={attLabels as any}
                  dataSets={estimatedValue as any}
                  xAxisTitle="Time"
                  yAxisTitle="Average Lift Value"
                  highlightAreaFrom={postTestStartDuration}
                  verticalLineAt={postTestStartDuration}
                  showSymbols={true}
                  height="400px"
                />
              </div>
            </Maximize>
          )}
        </div>

        <div id="aggregated-results" className={`tab-content ${activeTab === "aggregated-results" ? "block" : "hidden"}`}>
        {bestMarkets && bestMarkets.length > 0 && aggregatedResultsSchema && (
            <div>
              <DynamicForm
                formName="Aggregated Results Form"
                formSchema={aggregatedResultsSchema}
                onSubmit={onAggregatedResultsSubmit}
                validationCallback={validateAggregatedDurations}
                onSubmitText={"Calculate Aggregate Lift"}
                isLoading={isCalculateAggregateLiftLoading}
              />
            </div>
          )}

          {/* Divider between form and results */}
          <div className="my-8">
            <div className="border-t border-gray-200"></div>
          </div>

          {/* Display Results */}
          {liftPercentage !== null && incremental !== null && adsSpent !== null && roas !== null && (
            <div className="mt-4">
              <Maximize title="Lift Results">
                <div className="px-4 py-4">
                  <div className="flex justify-between px-4 py-4 space-x-4">
                    <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg w-1/2">
                      <span className="font-medium text-blue-600">
                        Lift Percentage: {liftPercentage.toFixed(2)}%
                      </span>
                    </div>
                    <div className="text-center py-2 mb-4 bg-blue-50 rounded-lg w-1/2">
                      <span className="font-medium text-blue-600">
                        Incremental Sales: {Math.round(incremental).toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <MultiBarLineChartECharts
                    labels={["Sales Without Campaign", "Sales With Campaign"]}
                    barDatasets={[
                      {
                        name: "Campaign Performance",
                        values: salesWithoutCampaign && salesWithCampaign ? [salesWithoutCampaign, salesWithCampaign] : [0, 0],
                        color: "#143969"
                      }
                    ]}
                    lineDatasets={[]}
                    showLabels={true}
                    showMinLine={true}
                  />
                </div>
              </Maximize>
            </div>
          )}

          {/* ROAS Results Chart */}
          {incremental !== null && adsSpent !== null && roas !== null && (
            <div className="mt-8">
              <Maximize title="ROAS Results">
                <GeoStudyResults
                  incrementalSales={incremental ?? 0}
                  adSpend={adsSpent ?? 0}
                  roas={roas ?? 0}
                />
              </Maximize>
            </div>
          )}

          {/* Line Chart */}
          {timeLabels && salesWithCampaignData && salesWithoutCampaignData && timeLabels.length > 0 && showChart && (
            <div className="mt-8">
              <Maximize title="Sales Comparison Over Time">
                <LineChart
                  key={`sales-comparison-${chartKey}`}
                  labels={timeLabels}
                  dataSets={[
                    {
                      name: 'Sales With Campaign',
                      data: salesWithCampaignData
                    },
                    {
                      name: 'Sales Without Campaign',
                      data: salesWithoutCampaignData,
                      lineType: "dashed"
                    }
                  ] as any}
                  xAxisTitle="Time"
                  yAxisTitle="Sales"
                  highlightAreaFrom={postTestStartDuration}
                  verticalLineAt={postTestStartDuration}
                  showSymbols={true}
                  height="500px"
                />
              </Maximize>
            </div>
          )}
        </div>
      </div>
    </NavBar>
  );
};

export default GeoLiftDashboard;
