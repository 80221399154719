import React from 'react';
import MultiBarLineChartECharts from './BarChart';

interface GeoStudyResultsProps {
  incrementalSales: number;
  adSpend: number;
  roas: number;
}

const GeoStudyResults: React.FC<GeoStudyResultsProps> = ({ incrementalSales, adSpend, roas }) => {
  const tableData = [
    { metric: 'Incremental Sales', value: incrementalSales.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }), roas: roas.toFixed(2) },
    { metric: 'Ad Spend', value: adSpend.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }), roas: '' },
  ];

  const labels = ['Ad Spend', 'Incremental Sales'];
  const barDatasets = [
    {
      name: 'Amount',
      values: [adSpend, incrementalSales],
      color: '#82ca9d'
    }
  ];

  return (
    <div className="p-4">
      <div className="mb-6">
        <table className="min-w-full border border-gray-300 shadow-sm rounded-lg overflow-hidden">
          <thead className="bg-gray-50 border-b border-gray-300">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-r border-gray-300">Metric</th>
              <th className="px-6 py-3 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider border-r border-gray-300">Value</th>
              <th className="px-6 py-3 text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">ROAS</th>
            </tr>
          </thead>
          <tbody className="bg-white">
            <tr className="border-b border-gray-300">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">Incremental Sales</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-r border-gray-300">
                {incrementalSales.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-semibold text-gray-900 text-center bg-gray-50" rowSpan={2}>
                {roas.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r border-gray-300">Ad Spend</td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-700 border-r border-gray-300">
                {adSpend.toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 })}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="mt-6">
        <MultiBarLineChartECharts
          labels={labels}
          barDatasets={barDatasets}
          lineDatasets={[]}
          showLabels={true}
        />
      </div>
    </div>
  );
};

export default GeoStudyResults;
