import React, { useEffect, useState } from "react";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import NavBar from "../NavBar";
import axiosInstance from "../../utils/axiosInstance";
import DynamicForm, { ExtendedFieldConfig } from "../DynamicForm";
import { z } from "zod";
import { Maximize2 } from "react-feather";
import LineChart, { LineData } from "../LineChart";
import Table, { TableColumn, TableRecord } from "../Table";
import { JobType } from "../../constants/JobTypes";
import { v4 as uuidv4 } from "uuid";
import Maximize from "../Maximize";
import TopLoadingBar from "../TopLoadingBar/TopLoadingBar";
import Alert, { AlertProps } from "../Alert";
import { useNavigate, useLocation } from "react-router-dom";
import HeatMap from "../HeatMap";
import Papa from "papaparse";
import { getRandomColor, getRandomColorWithOpacity } from "../../utils/utils";
import { getTenantId } from '../../utils/auth';

dayjs.extend(customParseFormat);

const dropDownOptionSchema = z.object({
  label: z.string(),
  value: z.string(),
});

let importExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  name: {
    validation: z
      .string()
      .min(1, "Please provide a name for the job. It helps in identifying the job in the list.")
      .max(100, "The name is too long. Please keep it under 100 characters."),
    metadata: {
      type: "text",
      label: "Name",
      group: "row1",
      fieldInfo: "Name of the Job to identify in Jobs list",
    },
  },
  campaignType: {
    validation: z.string().min(1, "Please select a campaign type for the geo lift analysis."),
    metadata: {
      type: "select",
      label: "Campaign Type",
      options: [
        { label: "National Buy", value: "national" },
        { label: "Non-National Buy", value: "non_national" }
      ],
      group: "row1",
      fieldInfo: "Type of Campaign for the geo lift analysis",
    },
  },
  geoLiftDataFile: {
    validation: z.any().refine((file) => file && file.length > 0, {
      message: "Please upload a CSV file. It is required to run the geo lift model.",
    }),
    metadata: {
      type: "file",
      label: "Data CSV File",
      group: "row7a",
      accepts: ".csv",
      showIf: [],
      fieldInfo: "CSV file on which you want to run the model",
    },
  },
  locationVariable: {
    validation: z.string().min(1, "Please select a location variable. This helps in grouping and analyzing data by location."),
    metadata: {
      type: "select",
      label: "Location Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Select the column that identifies unique geographic locations or regions (e.g., Store ID, City, ZIP code). This will be used to group and analyze data by location.",
    },
  },
  kpiVariable: {
    validation: z.string().min(1, "Please select a KPI variable. This is the target metric for measuring campaign effectiveness."),
    metadata: {
      type: "select",
      label: "KPI Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Choose the metric you want to measure the impact on (e.g., Sales, Revenue, Conversions). This is your target variable for measuring the effectiveness of the campaign.",
    },
  },
  dateVariable: {
    validation: z.string().min(1, "Please select a date variable. This is essential for tracking changes over time."),
    metadata: {
      type: "select",
      label: "Date Variable",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row8",
      showIf: [],
      fieldInfo:
        "Select the column containing dates for your time-series data. This will be used to track changes over time and establish pre/post campaign periods.",
    },
  },
  dateFormat: {
    validation: z.any(),
    metadata: {
      type: "select",
      label: "Date Format",
      options: [
        {
          label: "MM/DD/YYYY",
          value: "%m/%d/%Y",
        },
        {
          label: "MM/DD/YY",
          value: "%m/%d/%y",
        },
        {
          label: "YYYY-MM-DD",
          value: "%Y-%m-%d",
        },
        {
          label: "DD/MM/YYYY",
          value: "%d/%m/%Y",
        },
        {
          label: "DD/MM/YY",
          value: "%d/%m/%y",
        },
        {
          label: "YYYY/MM/DD",
          value: "%Y/%m/%d",
        },
        {
          label: "DD-MM-YYYY",
          value: "%d-%m-%Y",
        },
        {
          label: "DD-MM-YY",
          value: "%d-%m-%y",
        },
        {
          label: "YYYY.MM.DD",
          value: "%Y.%m.%d",
        },
        {
          label: "DD.MM.YYYY",
          value: "%d.%m.%Y",
        },
        {
          label: "MM-DD-YYYY",
          value: "%m-%d-%Y",
        },
        {
          label: "YYYY/MM/DD HH:MM:SS",
          value: "%Y/%m/%d %H:%M:%S",
        },
        {
          label: "DD/MM/YYYY HH:MM",
          value: "%d/%m/%Y %H:%M",
        },
        {
          label: "YYYY-MM-DDTHH:MM:SS",
          value: "%Y-%m-%dT%H:%M:%S",
        },
      ],
      group: "row8",
      showIf: [],
      fieldInfo: "Date format of the date column you have selected",
    },
  },
};

let edaExtendedFormSchema: Record<string, ExtendedFieldConfig> = {
  numberOfTestMarket: {
    validation: z
      .string()
      .min(1, "Number of markets is required")
      .refine(
        (val) => {
          const numbers = val.split(',').map(n => n.trim());
          return numbers.every(n => {
            const num = parseInt(n);
            return /^\d+$/.test(n) && num >= 2;
          });
        },
        {
          message: "Each number must be 2 or greater (e.g., '2,3,4,5')"
        }
      ),
    metadata: {
      type: "text",
      label: "Control Group Sizes",
      group: "row1",
      fieldInfo: "Specify the different control group sizes to test, separated by commas (e.g., '2,3,4,5'). Each number represents a potential control group size. The model will evaluate all possible combinations of markets for each size to find the best-matching control group. For example, '2,3,4' will test combinations of 2 markets, 3 markets, and 4 markets.",
      defaultValue: "2,3,4,5"
    },
  },
  treatmentPeriod: {
    validation: z
      .string()
      .min(1, "Validation period is required")
      .refine(
        (val) => {
          const numbers = val.split(',').map(n => n.trim());
          if (numbers.length > 3) return false;
          const validNumbers = numbers.every(n => {
            const num = parseInt(n);
            return /^\d+$/.test(n) && num > 0 && num < 50;
          });
          return validNumbers;
        },
        {
          message: "Enter 1-3 numbers between 1-49 (e.g., '5,10,15')"
        }
      ),
    metadata: {
      type: "text",
      label: "Validation Period Lengths (%)",
      group: "row1",
      fieldInfo: "Specify 1-3 different validation period lengths as percentages (e.g., '5,10,15'). These percentages determine how much of your pre-campaign data will be used for model validation. For each percentage, the model will use that portion of the pre-campaign period to validate its predictions. Lower percentages (e.g., 5%) provide more training data but less validation, while higher percentages (e.g., 15%) provide more thorough validation but less training data.",
      defaultValue: "5,10,15"
    },
  },
  includeMarket: {
    validation: z
      .array(dropDownOptionSchema)
      .min(1, "Please select at least one test market"),
    metadata: {
      type: "multi-select",
      label: "Test Markets",
      selectedOptions: [],
      options: [],
      group: "row2",
      fieldInfo: "Select the markets where you implemented or plan to implement your campaign. These are your test markets, and the model will measure their performance against synthetic control groups created from the remaining markets. Choose markets that are representative of your typical market conditions and have reliable data quality.",
    },
  },
  excludeMarket: {
    validation: z
      .array(dropDownOptionSchema)
      .min(0),
    metadata: {
      type: "multi-select",
      label: "Exclude Markets",
      selectedOptions: [],
      options: [],
      group: "row2",
      showIf: [],
      fieldInfo:
        "Select markets to exclude from the analysis. These markets won't be considered for either test or control groups. Exclude markets if they have data quality issues or if they're not representative of your typical market conditions.",
    }
  },
  budget: {
    validation: z.string()
      .min(1, "Budget is required")
      .refine(
        (val) => !isNaN(Number(val)) && Number(val) > 0,
        "Budget must be a positive number"
      ),
    metadata: {
      type: "text",
      label: "Campaign Budget",
      defaultValue: "10000000",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      formatter: {
        type: "currency",
        decimalScale: 2
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Enter your total campaign budget. This will be used to calculate potential ROI and optimize market allocation.",
    }
  },
  cpic: {
    validation: z.string()
      .min(1, "Cost Per Incremental Conversion is required")
      .refine(
        (val) => !isNaN(Number(val)) && Number(val) > 0,
        "Cost Per Incremental Conversion must be a positive number"
      ),
    metadata: {
      type: "text",
      label: "Cost Per Incremental Conversion",
      options: [],
      defaultValue: "7.5",
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      formatter: {
        type: "currency",
        decimalScale: 2
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "The average cost required to generate one additional conversion. This helps estimate the campaign's efficiency and ROI.",
    }
  },
  confidence: {
    validation: z.string()
      .min(1, "Confidence level is required")
      .refine(
        (val) => {
          const num = Number(val);
          return !isNaN(num) && num >= 1 && num <= 99;
        },
        "Confidence level must be between 1 and 99"
      ),
    metadata: {
      type: "text",
      defaultValue: "90",
      label: "Confidence Level (%)",
      options: [],
      dynamicOptions: {
        field: "geoLiftDataFile",
      },
      group: "row7",
      showIf: [],
      fieldInfo:
        "Set your desired confidence level (1-99%). A higher confidence level (e.g., 90%) means you want to be more certain about the results, but may require stronger evidence of effect. Lower values allow for more sensitivity but increase the chance of false positives.",
    }
  }
};

const JobConfigCreateGeoLift = () => {

  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const jobConfigId = query.get('jobConfigId');

  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [alertType, setAlertType] = useState<AlertProps["type"]>("success");
  const [alertMessage, setAlertMessage] = useState<string | undefined>(
    undefined
  );

  const [lineData, setLineData] = useState<LineData[]>([]);
  const [allLineData, setAllLineData] = useState<LineData[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [selectedMarkets, setSelectedMarkets] = useState<Set<string>>(new Set());
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [dataByLocation, setDataByLocation] = useState<{ [location: string]: { date: string; value: number }[] }>({});

  const filteredMarkets = allLineData
    .filter(item => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => {
      // Always put Average Market at the top
      if (a.name === "Average of All Markets") return -1;
      if (b.name === "Average of All Markets") return 1;
      return a.name.localeCompare(b.name);
    });

  const handleMarketSelection = (marketName: string) => {
    setSelectedMarkets(prevMarkets => {
      const newSelectedMarkets = new Set(prevMarkets);
      if (newSelectedMarkets.has(marketName)) {
        newSelectedMarkets.delete(marketName);
      } else {
        newSelectedMarkets.add(marketName);
      }
      // Update chart data immediately after state change
      const filteredData = allLineData.filter(item => newSelectedMarkets.has(item.name));
      setLineData([...filteredData]);
      return newSelectedMarkets;
    });
  };

  const handleSelectAll = () => {
    const allMarkets = new Set(allLineData.map(item => item.name));
    setSelectedMarkets(allMarkets);
    updateChartData(allMarkets);
  };

  const handleClearAll = () => {
    // When clearing all, set to Average Market only
    const defaultMarket = new Set(["Average of All Markets"]);
    setSelectedMarkets(defaultMarket);
    updateChartData(defaultMarket);
  };

  const updateChartData = (selectedMarkets: Set<string>) => {
    const filteredData = allLineData.filter(item => selectedMarkets.has(item.name));
    setLineData([...filteredData]); // Create a new array to ensure state update
  };

  const [importGeoLiftFormState, setImportGeoLiftFormState] = useState<any>([]);
  const [formSchema, setFormSchema] = useState<Record<string, ExtendedFieldConfig>>(edaExtendedFormSchema);

  useEffect(() => {
    if (importGeoLiftFormState.campaignType === 'national') {
      const { includeMarket, ...restSchema } = edaExtendedFormSchema;
      setFormSchema(restSchema);
    } else {
      setFormSchema(edaExtendedFormSchema);
    }
  }, [importGeoLiftFormState.campaignType]);

  const submitImportForm = async (data: Record<string, any>) => {
    try {
      
      setLoading(true);
      setImportGeoLiftFormState(data);

      if (data.geoLiftDataFile[0]) {
        Papa.parse(data.geoLiftDataFile[0], {
          header: true, // Treat the first row as headers
          complete: (result) => {
            const parsedData = result.data;
            const dataByLocation: { [location: string]: { date: string; value: number }[] } = {};

            // Parse the data and group by location
            parsedData.forEach((row: any) => {

              const date = row[data.dateVariable]
              const kpi = row[data.kpiVariable]
              const location = row[data.locationVariable]?.trim() // Trim whitespace

              // Skip if location is empty or undefined
              if (!location) {
                return;
              }

              if (!dataByLocation[location]) {
                dataByLocation[location] = [];
              }
              dataByLocation[location].push({ date, value: Number(kpi) });
            });

            setDataByLocation(dataByLocation);

            // Extract unique dates from one of the locations
            const sampleLocation = Object.keys(dataByLocation)[0];
            const dateLabels = dataByLocation[sampleLocation].map((item) => item.date);
            setDates(dateLabels);

            // Calculate average market data
            const averageMarketData = dateLabels.map((date) => {
              const valuesForDate = Object.values(dataByLocation).map(locationData => 
                locationData.find(d => d.date === date)?.value || 0
              );
              const average = valuesForDate.reduce((sum, val) => sum + val, 0) / valuesForDate.length;
              return { date, value: average };
            });

            // Add average market to dataByLocation
            dataByLocation["Average of All Markets"] = averageMarketData;

            setDataByLocation(dataByLocation);

            // Extract unique locations
            const uniqueLocations = Object.keys(dataByLocation)
              .filter(location => location !== "Average of All Markets") // Exclude Average Market from options
              .map((location) => ({
                label: location
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' '),
                value: location,
              }));

            // Update the form schema for includeMarket and excludeMarket with unique locations
            edaExtendedFormSchema.includeMarket.metadata.options = uniqueLocations;
            edaExtendedFormSchema.excludeMarket.metadata.options = uniqueLocations;

            // Create the line data for each location
            const formattedData: LineData[] = Object.keys(dataByLocation).map((location) => {
              const dataPoints = dataByLocation[location].map((item) => item.value);
              
              return {
                name: location,
                data: dataPoints,
                borderColor: location === "Average of All Markets" ? "#143969" : getRandomColor(),
                backgroundColor: location === "Average of All Markets" 
                  ? getRandomColorWithOpacity(0.3, "#143969") 
                  : getRandomColorWithOpacity(0.3),
                borderWidth: location === "Average of All Markets" ? 3 : 2,
              };
            });

            setAllLineData(formattedData);
            // Set only Average Market as default selection
            const defaultMarket = new Set(["Average of All Markets"]);
            setSelectedMarkets(defaultMarket);
            setLineData([...formattedData.filter(item => defaultMarket.has(item.name))]);
            if (currentStep < steps.length - 1) {
              setCurrentStep(currentStep + 1);
            }
          },
        });
      }


      setLoading(false);
    } catch (e) {
      setLoading(false);
      setAlertMessage("Failed to import data.");
      setAlertType("error");
    }
  };


  const submitEDAForm = async (formData: Record<string, any>) => {
    
    
    // Convert confidence from percentage to decimal
    if (formData.confidence) {
      formData.confidence = (parseFloat(formData.confidence) / 100).toString();
    }

    // Get the total number of records for a location
    const locationVariable = importGeoLiftFormState.locationVariable;
    const firstLocation = Object.keys(dataByLocation)[0];
    const totalRecords = dataByLocation[firstLocation]?.length || 0;

    // Convert validation period percentages to actual numbers
    if (formData.treatmentPeriod) {
      const percentages = formData.treatmentPeriod.split(',').map((p: string) => p.trim());
      const actualPeriods = percentages.map((p: string) => {
        const percent = parseInt(p);
        return Math.round((percent / 100) * totalRecords);
      });
      formData.treatmentPeriod = actualPeriods.join(',');
    }
    
    // Process includeMarket and excludeMarket
    if (formData.includeMarket) {
      formData.includeMarket = formData.includeMarket.map((market: any) => market.value).join(";;;");
    }
    if (formData.excludeMarket) {
      formData.excludeMarket = formData.excludeMarket.map((market: any) => market.value).join(";;;");
    }

    try {
      setLoading(true);
      const locationVariable = importGeoLiftFormState.locationVariable;
      const kpiVariable = importGeoLiftFormState.kpiVariable;
      const dateVariable = importGeoLiftFormState.dateVariable;

      const jobId = jobConfigId == undefined ? uuidv4() : jobConfigId;
      if (jobConfigId == undefined) {
        const geoLiftFormData = new FormData();
        geoLiftFormData.append("file", importGeoLiftFormState.geoLiftDataFile[0]);
        const geoLiftFilePath = jobId + "/input/geo_lift_data.csv";
        geoLiftFormData.append("path", geoLiftFilePath);
        await axiosInstance.post("/api/s3/upload", geoLiftFormData);
      }

      // Parse CSV data to calculate durationType
      const csvData = await new Promise<any[]>((resolve) => {
        Papa.parse(importGeoLiftFormState.geoLiftDataFile[0], {
          complete: (results) => resolve(results.data),
          header: true
        });
      });

      // Get unique locations
      const locations = csvData
        .map(row => row[locationVariable])
        .filter((value, index, self) => self.indexOf(value) === index);
      
      // Take the first location's data
      const firstLocation = locations[0];
      const locationData = csvData.filter(row => row[locationVariable] === firstLocation);
      
      // Get dates for the selected location and parse them
      const dates = locationData.map(row => {
        const dateStr = row[dateVariable];
        const format = importGeoLiftFormState.dateFormat;
        // Convert the date format string from Python to JavaScript
        const jsFormat = format
        .replace(/%Y/g, 'YYYY')
        .replace(/%y/g, 'YY')
        .replace(/%m/g, 'MM')
          .replace(/%d/g, 'DD')
          .replace(/%H/g, 'HH')
          .replace(/%M/g, 'mm')
          .replace(/%S/g, 'ss');
        return dayjs(dateStr, jsFormat).toDate();
      });
      
      const sortedDates = dates.filter(d => !isNaN(d.getTime())).sort((a, b) => a.getTime() - b.getTime());
      
      // Calculate the average difference between consecutive dates
      let totalDiff = 0;
      for (let i = 1; i < sortedDates.length; i++) {
        totalDiff += (sortedDates[i].getTime() - sortedDates[i-1].getTime()) / (1000 * 60 * 60 * 24); // Convert to days
      }
      const avgDiffDays = totalDiff / (sortedDates.length - 1);
      
      // If average difference is close to 7 days, consider it weekly data
      const durationType = Math.abs(avgDiffDays - 7) < 1 ? 'weeks' : 'days';

      const payload = {
        jobConfigId: jobId,
        name: importGeoLiftFormState.name,
        jobType: JobType.GeoLift,
        tenantId: getTenantId(),
        dataSourceConfig: {
          dataSourceType: "FILE_CSV",
        },
        dataTransformationConfig: {},
        dataMappingConfig: {
          mappings: {
            geoLift: {
              columnMapping: {
                date_variables: [dateVariable],
                kpi_variables: [kpiVariable],
                location_variables: [locationVariable]
              },
            },
          },
        },
        modelVersion: "V1",
        modelParameterConfig: {
          parameters: {
            dateFormat: importGeoLiftFormState.dateFormat,
            buyType: importGeoLiftFormState.buyType,
            durationType: durationType,
            ...formData
          },
        },
        modelOutputConfig: {},
      };

      // Send the POST request using Axios
      if (jobConfigId == undefined) {
        const response = await axiosInstance.post("/api/jobConfig", payload);
        
        // Automatically execute the newly created job
        await axiosInstance.post(
          "/api/jobRun",
          { jobConfigId: jobId },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("authToken"),
            },
          }
        );
      } else {
        const response = await axiosInstance.put("/api/jobConfig", payload);
      }
      
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      }
      setLoading(false);
      navigate(`/measurement/geo-lift?toast=geo-lift`);
    } catch (error) {
      setLoading(false);
      setAlertMessage(`Failed to ${jobConfigId == undefined ? "create" : "update"} Job Configuration.`);
      setAlertType("error");
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const convertPythonDateFormatToDayjs = (pythonFormat: string): string => {
    return pythonFormat
      .replace(/%Y/g, 'YYYY')
      .replace(/%m/g, 'MM')
      .replace(/%d/g, 'DD');
  };

  const validateForm = async (data: Record<string, any>, setError: any) => {
    if (data.geoLiftDataFile[0]) {
      return new Promise<boolean>((resolve) => {
        Papa.parse(data.geoLiftDataFile[0], {
          header: true,
          complete: (result) => {
            const parsedData = result.data;
            const dayjsDateFormat = convertPythonDateFormatToDayjs(data.dateFormat);
            let dateFormatValid = true;

            parsedData.forEach((row: any) => {
              const date = row[data.dateVariable];
              const kpi = row[data.kpiVariable]?.replace(',', '');
              if (date && !dayjs(date, dayjsDateFormat, true).isValid()) {
                dateFormatValid = false;
              }
              if (kpi && isNaN(Number(kpi))) {
                setError("kpiVariable", {
                  type: "manual",
                  message: "The KPI variable in the CSV must be a number."
                });
                resolve(false);
              }
            });

            if (!dateFormatValid) {
              setError("dateFormat", {
                type: "manual",
                message: "The date format in the CSV does not match the selected format."
              });
              resolve(false);
            } else {
              resolve(true);
            }
          }
        });
      });
    }
    return true;
  };

  const steps = [
    {
      title: "Import Data",
      content: (
        <div className="py-8 px-16">
          <DynamicForm
            formName="Import Data Geo Lift Form"
            formSchema={importExtendedFormSchema}
            onSubmit={submitImportForm}
            onSubmitText={`🗃️ ${jobConfigId != undefined ? "Update " : ""}Import Data`}
            // onPrevious={prevStep}
            // onPreviousText={"Previous"}
            isLoading={loading}
            validationCallback={validateForm}
          />
        </div>
      ),
    },
    {
      title: "Synthetic Control Input",
      content: (
        <>
          <Maximize title="Geo Market Trend">
            {dates.length > 0 && (
              <div className="min-h-[800px] flex flex-col">
                <div className="flex justify-end mb-4">
                  <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-sm m-1">
                      Select Markets ({selectedMarkets.size}/{allLineData.length})
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </label>
                    <div tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-64">
                      <div className="p-2 border-b flex justify-between gap-2">
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleSelectAll();
                          }}
                        >
                          Select All
                        </button>
                        <button 
                          className="btn btn-xs btn-link"
                          onClick={(e) => {
                            e.preventDefault();
                            handleClearAll();
                          }}
                        >
                          Clear All
                        </button>
                      </div>
                      <div className="p-2 border-b">
                        <input
                          type="text"
                          placeholder="Search markets..."
                          className="input input-xs input-bordered w-full"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                      <div className="max-h-48 overflow-auto">
                        {filteredMarkets.length > 0 ? (
                          filteredMarkets.map((item) => (
                            <div key={item.name} className="form-control">
                              <label className="label cursor-pointer justify-start gap-2">
                                <input
                                  type="checkbox"
                                  className="checkbox checkbox-xs"
                                  checked={selectedMarkets.has(item.name)}
                                  onChange={() => handleMarketSelection(item.name)}
                                />
                                <span className="label-text">{item.name}</span>
                              </label>
                            </div>
                          ))
                        ) : (
                          <div className="text-sm text-gray-500 p-2 text-center">
                            No markets found
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1 relative">
                  {lineData.length > 0 ? (
                    <LineChart
                      key={lineData.map(d => d.name).join(',')} // Add a key based on selected markets
                      labels={dates}
                      dataSets={lineData}
                      xAxisTitle="Date"
                      yAxisTitle="Value"
                      height="700px"
                      showSymbols={false}
                    />
                  ) : (
                    <div className="absolute inset-0 flex items-center justify-center text-gray-500">
                      Please select at least one market to view the chart
                    </div>
                  )}
                </div>
              </div>
            )}
          </Maximize>
          <DynamicForm
            formName="Import Data Geo Lift Form"
            formSchema={formSchema}
            onSubmit={submitEDAForm}
            onSubmitText={`🗃️ ${jobConfigId != undefined ? "Update " : ""} Create Synthetic Control`}
            // onPrevious={prevStep}
            // onPreviousText={"Previous"}
            isLoading={loading}
          />
        </>
      ),
    }
  ];

  return (
    true ? (<NavBar>
      <div className={loading ? "" : "hidden"}>
        <TopLoadingBar></TopLoadingBar>
      </div>
      <div className="container mx-auto p-12">
        <p className="text-center pb-8 text-lg font-semibold">
          Create New Study
        </p>
        <div className="steps w-full flex justify-between items-center mb-8">
          {steps.map((step, index) => (
            <div
              key={index}
              className={`step flex-1 text-center  ${index <= currentStep ? "step-primary" : ""
                }`}
            >
              <div>{step.title}</div>
            </div>
          ))}
        </div>
        <div className="content mb-4">
          <div>{steps[currentStep].content}</div>
        </div>
      </div>
      {alertMessage && (
        <Alert
          message={alertMessage}
          type={alertType}
          callback={() => {
            setAlertMessage(undefined);
          }}
        ></Alert>
      )}
    </NavBar>) : null
  );
};

export default JobConfigCreateGeoLift;
