import React from 'react';
import ReactECharts from 'echarts-for-react';

interface GeoLiftPowerCurve {
    data: any
}

const GeoLiftPowerCurve: React.FC<GeoLiftPowerCurve> = ({ data }) => {
    // Function to format numbers with K and M suffixes
    const formatNumber = (value: number): string => {
        if (value >= 1000000) {
            return `$${(value / 1000000).toFixed(1)}M`;
        } else if (value >= 1000) {
            return `$${(value / 1000).toFixed(1)}K`;
        }
        return `$${value.toFixed(0)}`;
    };

    // Filter indices where effect size is >= 0
    const filteredIndices = Object.values(data.EffectSize)
        .map((value: any, index: number) => ({ value, index }))
        .filter(item => item.value >= 0)
        .map(item => item.index);

    // Filter data based on the indices
    const filteredData = {
        EffectSize: filteredIndices.map(i => data.EffectSize[i]),
        Power: filteredIndices.map(i => data.Power[i]),
        Investment: filteredIndices.map(i => data.Investment[i])
    };

    const option = {
        title: {
            text: 'GeoLift Power Curve',
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold',
                color: '#143969'  // adrsta blue
            },
            subtextStyle: {
                fontSize: 14,
                fontStyle: 'italic'
            }
        },
        grid: {
            top: 80,
            bottom: 60,
            left: 80,
            right: 80,
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                name: 'Effect Size',
                nameTextStyle: {
                    padding: [5, 0, 0, 0],
                    fontSize: 14,
                    color: '#143969'  // adrsta blue
                },
                data: filteredData.EffectSize.map((value: any) => `${(value * 100).toFixed(2)}%`),
                axisLabel: {
                    interval: 4,
                    rotate: 45,
                    color: '#143969'  // adrsta blue
                }
            },
            {
                type: 'category',
                name: 'Investment',
                nameTextStyle: {
                    padding: [5, 0, 0, 0],
                    fontSize: 14,
                    color: '#143969'  // adrsta blue
                },
                data: filteredData.Investment.map((value: any) => formatNumber(value)),
                position: 'top',
                axisLabel: {
                    interval: 4,
                    rotate: 45,
                    color: '#143969'  // adrsta blue
                }
            }
        ],
        yAxis: {
            type: 'value',
            name: 'Power',
            nameLocation: 'center',
            nameGap: 40,
            nameTextStyle: {
                fontSize: 14,
                color: '#143969'  // adrsta blue
            },
            min: 0,
            max: 100,
            axisLabel: {
                formatter: '{value} %',
                color: '#143969'  // adrsta blue
            }
        },
        series: [
            {
                name: 'Actual Values',
                type: 'line',
                data: filteredData.Power.map((value: any) => {
                    const percentageValue = value * 100;
                    return percentageValue.toFixed(2);
                }),
                lineStyle: {
                    width: 2,
                    color: '#143969'  // adrsta blue
                },
                itemStyle: {
                    color: '#143969'  // adrsta blue
                }
            }
        ],
        legend: {
            data: ['Actual Values'],
            bottom: 10,
            textStyle: {
                fontSize: 12,
                color: '#143969'  // adrsta blue
            }
        },
        tooltip: {
            trigger: 'axis',
            formatter: (params: any) => {
                const effectSize = (filteredData.EffectSize[params[0].dataIndex] * 100).toFixed(2);
                const power = params[0].data;
                const investment = formatNumber(filteredData.Investment[params[0].dataIndex]);
                return `<b>Avg Detected Lift: ${effectSize}%</b><br/>Power: ${power}%<br/>Investment: ${investment}`;
            }
        }
    };

    return <ReactECharts option={option} style={{ height: 400, width: '100%', padding: '20px' }} />;
};

export default GeoLiftPowerCurve;
