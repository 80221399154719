import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import { getTenantId } from '../utils/auth';
import PieChartECharts from "../components/PieChart";
import LineChart from "../components/LineChart"; // Import LineChart component
import MultiBarLineChartECharts from "../components/BarChart";
import { CONTRIBUTION_PERC_API, GEO_LIFT_POST_TEST_CACHE_API } from "../constants/API";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const [lastMMMJob, setLastMMMJob] = useState<any>(null);
  const [contributionData, setContributionData] = useState<any>({ values: [], labels: [] });
  const [mmmJobConfigList, setMMMJobConfigList] = useState([]);
  const [mmmJobRunList, setMMMJobRunList] = useState([]);
  const [lastMMMJobDetails, setLastMMMJobDetails] = useState<any>(null);
  const [geoLiftJobConfigList, setGeoLiftJobConfigList] = useState([]);
  const [geoLiftJobRunList, setGeoLiftJobRunList] = useState([]);
  const [lastGeoLiftJobDetails, setLastGeoLiftJobDetails] = useState<any>(null);
  const [spendShareData, setSpendShareData] = useState<{ data: number[], labels: string[] }>({ data: [], labels: [] });
  const [effectShareData, setEffectShareData] = useState<{ data: number[], labels: string[] }>({ data: [], labels: [] });
  const [geoLiftPostObsLabel, setGeoLiftPostObsLabel] = useState<string[]>();
  const [postTreatmentValue, setPostTreatmentValue] = useState<any>();
  const [attLabels, setATTLabels] = useState<string[]>();
  const [estimatedValue, setEstimatedValue] = useState<any>();
  const [liftPercentage, setLiftPercentage] = useState<number>();
  const [averageAtt, setAverageAtt] = useState<number>();
  const [startDuration, setStartDuration] = useState<number>();
  const [showChartLabels, setShowChartLabels] = useState(false);

  // Static data for Asset Segment chart
  const assetSegmentData = {
    labels: ['>$100K', '$100K - $250K', '$250K-$500K', '$500K-$1M', '$1M-$2M', '$2M+'],
    barDatasets: [
      {
        name: 'Lead Count',
        values: [94, 50, 68, 46, 33, 40],
        color: '#143969'
      },
      {
        name: 'Total Value',
        values: [0, 5, 6.8, 18.4, 13.2, 24],
        color: '#2E5C94'
      }
    ],
    lineDatasets: []
  };

  // Static data for Region chart
  const regionData = {
    labels: ['California', 'New York', 'Illinois', 'Florida', 'Pennsylvania'],
    barDatasets: [
      {
        name: 'High Net Worth',
        values: [227, 179, 119, 131, 77],
        color: '#143969'
      },
      {
        name: 'Low Net Worth',
        values: [413, 385, 260, 223, 201],
        color: '#2E5C94'
      }
    ],
    lineDatasets: []
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        // Fetch MMM Jobs
        const [mmmConfigResponse, mmmRunResponse] = await Promise.all([
          axiosInstance.get('/api/jobConfig/jobType/MMM'),
          axiosInstance.get('/api/jobRun/jobType/MMM')
        ]);
        
        // Fetch Geo Lift Jobs
        const [geoLiftConfigResponse, geoLiftRunResponse] = await Promise.all([
          axiosInstance.get('/api/jobConfig/jobType/GEO_LIFT'),
          axiosInstance.get('/api/jobRun/jobType/GEO_LIFT')
        ]);
        
        // Process MMM Jobs
        const successfulMMMRuns = mmmRunResponse.data.items.filter(
          (run: any) => run.jobStatus === "SUCCESSFUL"
        );
        
        const successfulMMMJobConfigIds = new Set(
          successfulMMMRuns.map((run: any) => run.jobConfigId)
        );
        
        const successfulMMMConfigs = mmmConfigResponse.data.items
          .filter((config: any) => successfulMMMJobConfigIds.has(config.jobConfigId))
          .sort((a: any, b: any) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB.getTime() - dateA.getTime();
          });

        // Process Geo Lift Jobs
        const successfulGeoLiftRuns = geoLiftRunResponse.data.items.filter(
          (run: any) => run.jobStatus === "SUCCESSFUL"
        );
        
        const successfulGeoLiftJobConfigIds = new Set(
          successfulGeoLiftRuns.map((run: any) => run.jobConfigId)
        );
        
        const successfulGeoLiftConfigs = geoLiftConfigResponse.data.items
          .filter((config: any) => successfulGeoLiftJobConfigIds.has(config.jobConfigId))
          .sort((a: any, b: any) => {
            const dateA = new Date(a.createdDate);
            const dateB = new Date(b.createdDate);
            return dateB.getTime() - dateA.getTime();
          });

        setMMMJobConfigList(successfulMMMConfigs);
        setMMMJobRunList(successfulMMMRuns);
        setGeoLiftJobConfigList(successfulGeoLiftConfigs);
        setGeoLiftJobRunList(successfulGeoLiftRuns);

        // Fetch details for latest successful MMM job
        if (successfulMMMConfigs.length > 0) {
          const latestJobConfig = successfulMMMConfigs[0]; // Now this will be the latest one
          const jobConfigId = latestJobConfig.jobConfigId;
          
          
          const jobDetailsResponse = await axiosInstance.get(`/api/jobConfig/${jobConfigId}`);
          
          
          // Fetch contribution data
          const mediaVariables = jobDetailsResponse.data.dataMappingConfig.mappings.mmm.columnMapping.media_variables;
          const contributionResponse = await axiosInstance.post(CONTRIBUTION_PERC_API, {
            spendColumns: mediaVariables,
            jobConfigId: jobConfigId,
            tenantId: getTenantId()
          });
          
          
          // Set spend share data
          if (contributionResponse.data.totalContribution) {
            const totalContribution = contributionResponse.data.totalContribution;
            const formattedData = Object.entries(totalContribution).map(([key, value]) => ({
              name: key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
              value: value as number
            }));
            
            setSpendShareData({
              data: formattedData.map(item => item.value),
              labels: formattedData.map(item => item.name)
            });
          }

          // Fetch effect share data from new API endpoint
          try {
            const effectShareResponse = await axiosInstance.get(`/api/mmm/output/${jobConfigId}`);
            let outputJson = effectShareResponse.data.result;
            if (typeof outputJson === "string") {
              try {
                outputJson = JSON.parse(outputJson);
              } catch (error) {
                console.error("Failed to parse JSON string:", error);
              }
            }
            
            
            if (outputJson["Contributions_chart"]) {
              const effectData = outputJson["Contributions_chart"].filter(
                (item: any) => item.Variables !== 'Base' && item.Variables !== 'Seasonality'
              );
              const labels = effectData.map((item: any) => item.Variables);
              const values = effectData.map((item: any) => Number(item.Contributions));
              
              setEffectShareData({
                data: values,
                labels: labels
              });
            }
          } catch (error) {
            console.error('Error fetching effect share data:', error);
          }
          
          setLastMMMJobDetails({
            jobConfig: jobDetailsResponse.data,
            contribution: contributionResponse.data
          });
        }

        // Fetch details for latest successful Geo Lift job
        if (successfulGeoLiftConfigs.length > 0) {
          const latestJobConfig = successfulGeoLiftConfigs[0]; // Now this will be the latest one
          const jobConfigId = latestJobConfig.jobConfigId;
          
          
          const jobDetailsResponse = await axiosInstance.get(`/api/jobConfig/${jobConfigId}`);
          
          
          setLastGeoLiftJobDetails({
            jobConfig: mmmConfigResponse.data,
            output: jobDetailsResponse.data
          });

          // Fetch Geo Lift output data
          try {
            const formData = new FormData();
            formData.append('tenantId', getTenantId());
            formData.append('jobConfigId', jobConfigId);

            const geoLiftOutputResponse = await axiosInstance.post(GEO_LIFT_POST_TEST_CACHE_API, formData);

            const geoLiftPlot = geoLiftOutputResponse.data.data.lift_plot;
            const labels = Object.values(geoLiftPlot.Time).map((time: any) => time.toString());
            setGeoLiftPostObsLabel(labels);
            const c_obsData = Object.values(geoLiftPlot.c_obs);
            const t_obsData = Object.values(geoLiftPlot.t_obs);

            const dataSets = [
              {
                name: "Control Observations",
                lineType: "dashed",
                data: c_obsData,
                borderColor: "#d6279a",
                backgroundColor: "rgba(214, 39, 154, 0)"
              },
              {
                name: "Treatment Observations",
                data: t_obsData,
                borderColor: "#006400",
                backgroundColor: "rgba(0, 100, 0, 0)"
              },
            ];

            setPostTreatmentValue(dataSets);

            const attPlot = geoLiftOutputResponse.data.data.att_plot;
            const attLabels = Object.values(attPlot.Time).map((time: any) => time.toString());
            setATTLabels(attLabels);
            const estimateData = Object.values(attPlot.Estimate).map((value: any) =>
              parseFloat(value.toFixed(2))
            );
            const estimatedDataSets = [
              {
                type: "line",
                lineType: "dashed",
                name: "ATT",
                data: estimateData,
                borderColor: "#3b82f6",
                backgroundColor: "rgba(59, 130, 246, 0.0)"
              }
            ];

            setEstimatedValue(estimatedDataSets);
            setLiftPercentage(geoLiftOutputResponse.data.data.lift_percentage);
            setAverageAtt(geoLiftOutputResponse.data.data.average_att);
            setStartDuration(geoLiftOutputResponse.data.data.start_duration);

            setLastGeoLiftJobDetails({
              jobConfig: mmmConfigResponse.data,
              output: geoLiftOutputResponse.data.data
            });
          } catch (error) {
            console.error('Error fetching Geo Lift output data:', error);
          }
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const modelRoutes = {
    "Marketing Mix Model": "/planning/marketing-mix-model",
    "Bid Optimizer": "/optimization/bid-optimizer",
    "Geo-Based Incrementality": "/measurement/geo-lift"
  };

  return (
    <>
      <NavBar>
        <div className="h-svh">
        <div className="text-5xl font-semibold bg-[#143969] text-center py-8 text-white">
          Adrsta <span className="text-yellow-500">Marketing Science</span>
        </div>
        
        {/* Planning Section with Blue Background */}
        <div className="bg-[#143969] px-20 py-12 h-svh">
          <div className="flex justify-between">
            {/* <div className="text-2xl mt-32 font-semibold text-white flex flex-col">
              <div>Decision</div>
              <div className="mt-12">Adrsta AI Model</div>
            </div> */}
            
            {/* Model Cards */}
            {[
              {
                id: 1,
                name: "Planning",
                title: "Cross-Channel Budget Allocation",
                subtitle: ["Marketing Mix Model"]
              },
              {
                id: 2,
                name: "Buying",
                title: "Impression-Level Bid Optimization",
                subtitle: ["Bid Optimizer", "Next Best Action"]
              },
              {
                id: 3,
                name: "Measurement",
                title: "Test and Learn + ROI Measurement",
                subtitle: ["Geo-Based Incrementality"]
              }
            ].map(item => {
              return (
                <div key={item.id} className="ml-8 relative">
                  <div className="header rounded-t flex bg-white justify-center h-16 items-center">
                    <span className="text-3xl text-[#143969] text-center">{item.name}</span>
                  </div>
                  <div className="h-80 bg-[#143969] border border-[4px] border-white rounded-b flex flex-col space-y-4 px-8">
                    <span className="text-white text-2xl text-center mt-12 mb-8 italic">
                      {item.title}
                    </span>
                    <div className="flex flex-col">
                      {item.subtitle.map((subtitle, index) => {
                        const isClickable = subtitle !== "Next Best Action";
                        return isClickable ? (
                          <button
                            key={index}
                            onClick={() => navigate(modelRoutes[subtitle as keyof typeof modelRoutes])}
                            className="text-white text-3xl text-center mt-4 hover:text-yellow-500 transition-colors duration-200 underline underline-offset-4"
                          >
                            {subtitle}
                          </button>
                        ) : (
                          <span key={index} className="text-gray-400 text-3xl text-center mt-4">
                            {subtitle} <span className="text-sm">(Beta)</span>
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        </div>
      </NavBar>
    </>
  );
};

export default Home;
