import { TableColumn } from "../../../components/Table";
import React, { useEffect, useState } from "react";
import { JobPendingComponentProps } from "../../../models/interfaces";
import JobConfigView from "../../../components/JobConfigView";
import { JobType } from "../../../constants/JobTypes";
import axiosInstance from "../../../utils/axiosInstance";

const columns: TableColumn[] = [
  { key: "name", label: "Name" },
  { 
    key: "createdDate", 
    label: "Created Date",
    formatter: {
      type: "date"
    }
  },
  { key: "status", label: "Status" },
];

const geoBreadCrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Measurement",
    url: "/measurement/geo-lift",
  },
  {
    name: "Geo Lift Measurement",
    url: "/measurement/geo-lift",
  },
];

const GeoLift: React.FC = () => {
  const [jobConfigList, setJobConfigList] = useState([]);
  const [jobRunList, setJobRunList] = useState([]);
  const [showToast, setShowToast] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search || window.location.hash.split('?')[1]);

    const fetchData = async () => {
      try {
        const jobConfigListResponse = await axiosInstance.get("/api/jobConfig/jobType/GEO_LIFT");
        setJobConfigList(jobConfigListResponse.data.items);
        const jobRunListResponse = await axiosInstance.get("/api/jobRun/jobType/GEO_LIFT");
        setJobRunList(jobRunListResponse.data.items);
        setLoading(false); // Data loading complete

        if (urlParams.get('toast') === 'geo-lift') {
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
            urlParams.delete('toast');
            const newUrl = window.location.hash ? `${window.location.pathname}${window.location.hash.split('?')[0]}?${urlParams.toString()}` : `${window.location.pathname}?${urlParams.toString()}`;
            window.history.replaceState({}, '', newUrl);
          }, 3000);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const interval = setInterval(fetchData, 15000); // 15 seconds

    return () => clearInterval(interval);
  }, []); // Empty dependency array to run once on mount

  return (
    <>
      <JobConfigView
        jobConfigList={jobConfigList}
        jobRunList={jobRunList}
        breadCrumbs={geoBreadCrumbs}
        jobType={JobType.GeoLift}
        tableColumns={columns}
      />
      {!loading && showToast && (
        <div className="toast toast-bottom">
          <div className="alert alert-warning">
            <div>
              <span>🚀 Geo Lift study will take around 20 - 30 mins based on data. 📊 You will be notified by email. 📧</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GeoLift;
