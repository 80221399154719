import React from "react";

export interface TableColumn {
  key: string; // Unique key to identify the column
  label: string | React.ReactElement; // Display name of the column
  decimal?: number;
  prefix?: string; // Optional prefix for the column values
  postfix?: string; // Optional postfix for the column values
  formatter?: {
    type: "currency" | "date";
    format?: string;
    decimalScale?: number;
  };
}

export interface TableRecord {
  [key: string]: any;
}

export interface DynamicTableProps {
  columns: TableColumn[];
  records: TableRecord[];
  size?: "small" | "medium" | "large" | "extra-small"; // New size prop for controlling table size
}

const Table: React.FC<DynamicTableProps> = ({
  columns,
  records,
  size = "medium",
}) => {
  const formatValue = (value: any, column: TableColumn) => {
    let formattedValue = value;

    if (column.formatter) {
      if (column.formatter.type === "date") {
        const date = new Date(value);
        if (!isNaN(date.getTime())) {
          return date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          });
        }
      }
    }
    
    // Check if it's a pure number (only digits and decimal point)
    const isPureNumber = (val: any): boolean => {
      if (typeof val === "number") return true;
      const str = val?.toString() || '';
      // Only allow digits and at most one decimal point
      return /^\d*\.?\d+$/.test(str);
    };
    
    // Only format if it's a pure number
    if (isPureNumber(value)) {
      const numValue = typeof value === "number" ? value : parseFloat(value);
      formattedValue = numValue.toLocaleString(undefined, {
        minimumFractionDigits: column.decimal !== undefined ? column.decimal : 2,
        maximumFractionDigits: column.decimal !== undefined ? column.decimal : 2,
      });
    }

    // Add prefix if specified and it's a pure number
    if (column.prefix && isPureNumber(value)) {
      formattedValue = `${column.prefix} ${formattedValue}`;
    }

    // Add postfix if specified
    if (column.postfix) {
      formattedValue = `${formattedValue} ${column.postfix}`;
    }

    return formattedValue;
  };

  // Conditional classNames based on the table size prop
  const sizeClasses = {
    "extra-small": "table-xs text-xs p-1",
    small: "table-xs text-sm p-2",
    medium: "",
    large: "text-lg p-4",
  };

  return (
    <div className="relative w-full overflow-auto h-full">
      <table className={`table table-zebra w-full ${sizeClasses[size]}`}>
        <thead
          className="bg-gray-100"
          style={{ position: "sticky", top: 0, zIndex: 10 }}
        >
          <tr className={sizeClasses[size]}>
            {columns.map((column) => (
              <th key={column.key}>{column.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {records.map((record, index) => (
            <tr key={index} className={sizeClasses[size]}>
              {columns.map((column) => (
                <td key={column.key}>
                  {formatValue(record[column.key], column)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
