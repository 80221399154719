import React, { useState, useRef, useEffect } from 'react';
import { Send, X, MessageCircle } from 'react-feather';
import axiosInstance from '../../utils/axiosInstance';

interface Message {
  content: string;
  sender: 'user' | 'ai';
  timestamp: Date;
}

interface ChatProps {
  jobConfig?: any;
  tenantId?: string;
  apiEndpoint: string;
}

const formatMessage = (message: string) => {
  // Split the message into paragraphs
  const paragraphs = message.split('\n');
  
  return paragraphs.map((paragraph, pIndex) => {
    // Handle headers (lines starting with ###)
    if (paragraph.startsWith('###')) {
      return (
        <h3 key={`p-${pIndex}`} className="text-lg font-bold mt-4 mb-2 text-[#143969]">
          {paragraph.replace('###', '').trim()}
        </h3>
      );
    }

    // Handle list items (lines starting with number or bullet)
    if (/^\d+\./.test(paragraph.trim())) {
      // Split the list item into label and content
      const [label, ...rest] = paragraph.trim().split('. ');
      const content = rest.join('. ');
      
      return (
        <div key={`p-${pIndex}`} className="flex space-x-2 my-1">
          <span className="font-semibold text-[#143969] min-w-[24px]">{label}.</span>
          <span>{formatInlineContent(content)}</span>
        </div>
      );
    }

    // Handle empty lines
    if (!paragraph.trim()) {
      return <div key={`p-${pIndex}`} className="h-2" />;
    }

    // Handle regular paragraphs
    return (
      <p key={`p-${pIndex}`} className="my-1">
        {formatInlineContent(paragraph)}
      </p>
    );
  });
};

const formatInlineContent = (text: string) => {
  // Split the text into parts based on markers
  const parts = text.split(/(\*\*.*?\*\*)|(`.*?`)|(\d+(?:\.\d+)?)/g);

  return parts.map((part, index) => {
    if (!part) return null;
    
    if (part.startsWith('**') && part.endsWith('**')) {
      // Bold text
      return (
        <span key={index} className="font-bold">
          {part.slice(2, -2)}
        </span>
      );
    } else if (part.startsWith('`') && part.endsWith('`')) {
      // Code box
      return (
        <span key={index} className="inline-block px-1 bg-blue-100 rounded border border-blue-200 font-mono text-sm text-blue-800">
          {part.slice(1, -1)}
        </span>
      );
    } else if (/^\d+(?:\.\d+)?$/.test(part)) {
      // Numbers
      return (
        <span key={index} className="font-semibold text-[#143969]">
          {part}
        </span>
      );
    } else {
      // Regular text
      return <span key={index}>{part}</span>;
    }
  });
};

const Chat: React.FC<ChatProps> = ({ jobConfig, tenantId, apiEndpoint }) => {
  const [messages, setMessages] = useState<Message[]>([
    {
      content: "Hi! I'm your AI assistant. How can I help you today?",
      sender: 'ai',
      timestamp: new Date(),
    },
  ]);
  const [inputMessage, setInputMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = async () => {
    if (!inputMessage.trim()) return;

    const userMessage: Message = {
      content: inputMessage,
      sender: 'user',
      timestamp: new Date(),
    };

    setMessages((prev) => [...prev, userMessage]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const response = await axiosInstance.post(apiEndpoint, {
        message: inputMessage,
        jobConfig,
        tenantId
      });

      const aiMessage: Message = {
        content: response.data.data.analysis || response.data.data.message || 'Sorry, I could not process your request.',
        sender: 'ai',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, aiMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      const errorMessage: Message = {
        content: 'Sorry, there was an error processing your request. Please try again.',
        sender: 'ai',
        timestamp: new Date(),
      };
      setMessages((prev) => [...prev, errorMessage]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  return (
    <>
      {/* Chat Toggle Button */}
      <button
        onClick={() => setIsOpen(true)}
        className={`fixed bottom-4 right-4 bg-[#143969] text-white p-4 rounded-full shadow-lg hover:bg-[#0f2d4f] transition-all duration-300 ${
          isOpen ? 'translate-x-[calc(40rem+1rem)] opacity-0' : 'translate-x-0 opacity-100'
        }`}
      >
        <MessageCircle className="h-6 w-6" />
      </button>

      {/* Chat Drawer */}
      <div
        className={`fixed top-0 right-0 h-full w-[40rem] bg-white shadow-lg transform transition-transform duration-300 ease-in-out z-50 flex flex-col ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center px-4 py-3 bg-[#143969] text-white">
          <h3 className="font-semibold">AI Assistant</h3>
          <X
            className="w-5 h-5 cursor-pointer hover:text-blue-200"
            onClick={() => setIsOpen(false)}
          />
        </div>

        {/* Messages */}
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`flex ${
                  message.sender === 'user' ? 'justify-end' : 'justify-start'
                }`}
              >
                <div
                  className={`max-w-[85%] p-3 rounded-lg whitespace-pre-wrap ${
                    message.sender === 'user'
                      ? 'bg-[#143969] text-white rounded-br-none'
                      : 'bg-gray-100 text-gray-800 rounded-bl-none'
                  }`}
                >
                  {message.sender === 'ai' ? formatMessage(message.content) : message.content}
                </div>
              </div>
            ))}
            {isLoading && (
              <div className="flex justify-start">
                <div className="max-w-[80%] rounded-lg p-3 bg-gray-100">
                  <div className="flex items-center">
                    <div className="animate-pulse flex space-x-1.5">
                      <div className="h-2.5 w-2.5 bg-[#143969] rounded-full animate-[bounce_0.7s_infinite]"></div>
                      <div className="h-2.5 w-2.5 bg-[#143969] rounded-full animate-[bounce_0.7s_infinite_0.2s]"></div>
                      <div className="h-2.5 w-2.5 bg-[#143969] rounded-full animate-[bounce_0.7s_infinite_0.4s]"></div>
                    </div>
                    <span className="ml-3 text-sm text-gray-500">AI is thinking...</span>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </div>

        {/* Input */}
        <div className="p-4 bg-white border-t">
          <div className="flex items-center space-x-2">
            <textarea
              value={inputMessage}
              onChange={(e) => setInputMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Type your message..."
              className="flex-1 p-2 border rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-[#143969]"
              rows={1}
            />
            <button
              onClick={handleSend}
              disabled={!inputMessage.trim() || isLoading}
              className="p-2 bg-[#143969] text-white rounded-lg hover:bg-[#0f2d4f] disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </div>
      </div>

      {/* Overlay */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 z-40"
          onClick={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default Chat;
