import React, { useEffect, useState } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import NavBar from "../../../../components/NavBar";
import DynamicForm, {
  ExtendedFieldConfig,
} from "../../../../components/DynamicForm";
import { z } from "zod";
import PieChart from "../../../../components/PieChart";
import WaterFallChart from "../../../../components/WaterFallChart";
import axiosInstance from "../../../../utils/axiosInstance";
import LineChart, {
  LineChartProps,
  LineData,
} from "../../../../components/LineChart";
import Table, { TableColumn, TableRecord } from "../../../../components/Table";
import Maximize from "../../../../components/Maximize";
import GradientStackedAreaChart from "../../../../components/GradientStackedAreaChart";
import { CONTRIBUTION_PERC_API, MMM_INPUT_DATA, OPTIMIZE_SPEND_API, MMM_CHAT_API } from "../../../../constants/API";
import { Maximize2 } from "react-feather";
import HeatMap from "../../../../components/HeatMap";
import ScenarioTable from "../../../../components/ScenarioTable";
import MultiBarLineChartECharts, { MultiBarLineChartProps } from "../../../../components/BarChart";
import PieChartECharts from "../../../../components/PieChart";
import Chat from "../../../../components/Chat/Chat";
import { getTenantId } from '../../../../utils/auth';

const initialBreadcrumbs = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Planning",
    url: "/planning/marketing-mix-model",
  },
  {
    name: "Marketing Mix Model",
    url: "/planning/marketing-mix-model",
  },
];

interface MMMDashboardProps {
  details: any;
  result: any;
}

const generateSaturationCharts = (response: any) => {
  const colors = [
    "rgba(75, 192, 192, 1)",
    "rgba(255, 99, 132, 1)",
    "rgba(54, 162, 235, 1)",
    "rgba(255, 206, 86, 1)",
    "rgba(153, 102, 255, 1)",
    "rgba(255, 159, 64, 1)",
  ];

  const charts: any[] = [];
  const allKeys = Object.keys(response);

  allKeys.forEach((key, index) => {
    if (key.startsWith("Saturation_")) {
      const data = response[key];
      const datasetName = key
        .replaceAll("Saturation_", "")
        .split('_')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
        .replaceAll(/_/g, " ");
      const color = colors[index % colors.length];
      const labels = data.map((item: any) => item.Spends);
      const lineData = data.map((item: any) => {
        return item[key.replaceAll("Saturation_", "")];
      });
      const chartProps: LineChartProps = {
        labels,
        dataSets: [
          {
            name: datasetName,
            data: lineData,
            borderColor: color,
            backgroundColor: color.replaceAll("1)", "0.3)"),
          },
        ],
        xAxisTitle: "Spends",
        yAxisTitle: datasetName,
      };

      charts.push(
        <div key={`${key}-${index}`} className="" style={{ width: "calc(50% - 8px)" }}>
          <div className="w-full">
            <Maximize title={datasetName}>
              <LineChart key={key} {...chartProps} />
            </Maximize>
          </div>
        </div>
      );
    }
  });

  return charts;
};

function normalizeData(data: any) {
  const min = Math.min(...data);
  const max = Math.max(...data);
  return data.map((value: any) => (value - min) / (max - min));
}

let validationCallback: any = (data: any, setError: any) => {
  
}

const multiColinearityColumn: TableColumn[] = [
  { key: "variable", label: "Variable" },
  { key: "value", label: "Value", decimal: 3 },
];

let scenarioData: any = {
  'Scenario 1': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 2': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 3': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 4': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  },
  'Scenario 5': {
    "variables": { "Total Budget": "", "Weeks": "" },
    "yearlySpendChartData": [],
    "contributionChartData": [],
    "labels": []
  }
};

const MMMDashboard: React.FC = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("name") ? decodeURIComponent(params.get("name") as string) : "";

  let [breadcrumbs, setBreadcrumbs] = useState(initialBreadcrumbs);
  const { id } = useParams<{ id: string }>(); // Extract UUID from URL

  useEffect(() => {
    setBreadcrumbs((prevBreadcrumbs: any) => {
      if (!prevBreadcrumbs.some((breadcrumb: any) => breadcrumb.name === name)) {
        return [
          ...prevBreadcrumbs,
          { name: name, url: `/planning/marketing-mix-model/${id}` },
        ];
      }
      return prevBreadcrumbs;
    });
  }, [id]);

  const [contributionChartData, setContributionChartData] = useState([]);
  const [contributionChartLabel, setContributionChartLabel] = useState([]);

  const [saturationCharts, setSaturationCharts] = useState([]);

  const [yearlyOptimizedSpendChartData, setYearlyOptimizedSpendChartData] =
    useState([]);
  const [yearlyOptimizedSpendChartLabel, setYearlyOptimizedSpendChartLabel] =
    useState([]);

  const [mediaYOYSpentChart, setMediaYOYSpentChart] = useState<any>(undefined);

  const [roiFinalData, setRoiFinalData] = useState<any[]>([]);
  const [roiLabels, setRoiLabels] = useState<string[]>([]);

  const [actualPredictedLabels, setActualPredictedLabels] = useState([]);
  const [actualSales, setActualSales] = useState([]);
  const [predictedSales, setPredictedSales] = useState([]);

  const [y1, setY1] = useState([]);
  const [y2, setY2] = useState([]);
  const [correlationLabels, setCorrelationLabels] = useState([]);

  const [dateVariables, setDateVariables] = useState<string[]>([]);
  const [mediaVariables, setMediaVariables] = useState<string[]>([]);
  const [targetVariables, setTargetVariables] = useState<string[]>([]);
  const [marketLocations, setMarketLocations] = useState<string[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<string>('');

  const [waterFallChartLabel, setWaterFallChartLabel] = useState(undefined);
  const [waterFallChartData, setWaterFallChartData] = useState(undefined);

  const [salesDecompositionLabel, setSalesDecompositionLabel] = useState([]);
  const [salesDecompositionData, setSalesDecompositionData] = useState([]);

  const [historicSpendContributionLabel, setHistoricSpendContributionLabel] = useState<any>([]);
  const [historicSpendContributionData, setHistoricSpendContributionData] = useState<any>([]);
  const [effectShareLabel, setEffectShareLabel] = useState<string[]>([]);
  const [effectShareData, setEffectShareData] = useState<number[]>([]);

  const [elasticityColumn, setElasticityColumn] = useState<TableColumn[]>([]);
  const [elasticityRecord, setElasticityRecord] = useState<TableRecord[]>([]);

  const [seasonDataSet, setSeasonDataSet] = useState<LineData[]>([]);
  const [trendDataSet, setTrendDataSet] = useState<LineData[]>([]);
  const [seasonLabel, setSeasonLabel] = useState([]);
  const [modelAccuracy, setModelAccuracy] = useState([]);

  const [heatMapData, setHeatMapData] = useState<any>(undefined);
  const [similarityHeatMapData, setSimilarityHeatMapData] = useState<any>(undefined);

  const [adstockData, setAdstockData] = useState<any>(undefined);

  const [multiColinearityRecords, setMultiColinearityRecords] = useState<
    TableRecord[]
  >([]);

  const [isLoading, setLoading] = useState(false);

  const [showChartLabels, setShowChartLabels] = useState(false);  // Hidden by default

  const [jobConfig, setJobConfig] = useState<any>(null);

  useEffect(() => {
    axiosInstance.get(`/api/jobConfig/${id}`).then((response) => {
      const mediaVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.media_variables;
      const targetVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.target_variables;
      const dateVariables = response.data.dataMappingConfig.mappings.mmm.columnMapping.date_variables;
      const locations = response.data.dataMappingConfig.mappings.mmm.columnMapping.market_locations || [];

      setMediaVariables(mediaVariables);
      setTargetVariables(targetVariables);
      setDateVariables(dateVariables);
      setMarketLocations(locations);
      setJobConfig(response.data);

      // Set initial selected market if locations exist
      if (locations && locations.length > 0) {
        setSelectedMarket(locations[0]);
      }

      fetchCorrelationData(mediaVariables[0], targetVariables[0], dateVariables[0]);

      axiosInstance.post(CONTRIBUTION_PERC_API, {
        spendColumns: mediaVariables,
        jobConfigId: id,
        tenantId: getTenantId()
      }).then((response) => {
        const contributionData = response.data.totalContribution;

        // Extract labels and data for pie chart
        const labels: string[] = Object.keys(contributionData);
        const data: string[] = Object.values(contributionData);

        setHistoricSpendContributionLabel(labels);
        setHistoricSpendContributionData(data);
      });
      validationCallback = (data: any, setError: any) => {
        let totalContribution = 100;
        mediaVariables.forEach((item: string) => {
          if (data[item] !== "") {
            totalContribution -= Number.parseFloat(data[item]);
          }
        });

        if (totalContribution <= 0) {
          setError("form", {
            type: "manual",
            message: "Total Contribution addition should not be more than 100",
          });
          return false;
        }
        return true;
      }
      let groupCounter = 2; // Start from "row2"

      scenarioData = {
        'Scenario 1': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 2': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 3': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 4': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        },
        'Scenario 5': {
          "variables": { "Total Budget": "", "Weeks": "" },
          "yearlySpendChartData": [],
          "contributionChartData": [],
          "labels": []
        }
      };


      axiosInstance.get(`/api/mmm/output/${id}`).then((response) => {
        let outputJson = response.data.result;
        if (typeof outputJson === "string") {
          try {
            outputJson = JSON.parse(outputJson);
          } catch (error) {
            console.error("Failed to parse JSON string:", error);
          }
        }

        

        if (outputJson.ROI_Final) {
          setRoiFinalData(outputJson.ROI_Final);
          setRoiLabels(outputJson.ROI_Final.map((item: any) => item.MediaChannel));
        }
        setModelAccuracy(outputJson["ModelAccuracy"])
        const seasonalityData = outputJson["Seasonality"];

        scenarioData = {
          'Scenario 1': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 2': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 3': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 4': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          },
          'Scenario 5': {
            "variables": { "Total Budget": "", "Weeks": "" },
            "yearlySpendChartData": [],
            "contributionChartData": [],
            "labels": []
          }
        };
        outputJson["AllScenarios_BudgetAndMediaVariables"] && outputJson["AllScenarios_BudgetAndMediaVariables"].forEach((scenario: any) => {
          // Get the scenario key (e.g., "Scenario 1")
          const scenarioKey = scenario.Scenario;

          // Ensure scenarioData has a 'variables' object for each scenario
          if (!scenarioData[scenarioKey]) {
            scenarioData[scenarioKey] = {
              variables: {},
              yearlySpendChartData: [],
              labels: [],
            };
          }

          // Update Total Budget and Weeks for this scenario
          scenarioData[scenarioKey].variables['Total Budget'] = scenario['Total Budget'];
          scenarioData[scenarioKey].variables['Weeks'] = scenario['Weeks'];

          // Loop through all keys in the scenario object to find media variables
          Object.keys(scenario).forEach((key) => {
            if (!['Scenario', 'Total Budget', 'Weeks'].includes(key)) {

              // Add the key to the mediaVariables array if it's not already there
              if (!mediaVariables.includes(key)) {
                mediaVariables.push(key);
              }

              if (scenario["Scenario"] === 'Scenario 1') {
                scenarioData[scenarioKey].variables[key] = '0';
                scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`Final_Optimizer`].filter((item: any) => {
                  return item.Media === key
                })[0]['Yearly_Optimize_Spend']);

                // Optionally, you can update yearlySpendChartData and labels here, if necessary
                const otherSalesContribution = outputJson.Contributions_chart
                  .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
                  .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

                scenarioData[scenarioKey].predictedSales = outputJson[`Final_Optimizer`].reduce((sum: any, item: any) => sum + item.Contribution, 0) + otherSalesContribution;
                scenarioData[scenarioKey].labels.push(key);
              } else {
                scenarioData[scenarioKey].variables[key] = scenario[key];

                scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`${scenario["Scenario"]}_Final_Optimizer`].filter((item: any) => {
                  return item.Media === key
                })[0]['Yearly_Optimize_Spend']);

                // Optionally, you can update yearlySpendChartData and labels here, if necessary
                const otherSalesContribution = outputJson.Contributions_chart
                  .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
                  .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

                scenarioData[scenarioKey].predictedSales = outputJson[`${scenario["Scenario"]}_Final_Optimizer`].reduce((sum: any, item: any) => sum + item.Contribution, 0) + otherSalesContribution;
                scenarioData[scenarioKey].labels.push(key);
              }
            }
          });
        });

        !outputJson["AllScenarios_BudgetAndMediaVariables"] && [1, 2, 3, 4, 5].forEach((scenario: any) => {

          // Update Total Budget and Weeks for this scenario
          scenarioData[`Scenario ${scenario}`].variables['Total Budget'] = '20000000';
          scenarioData[`Scenario ${scenario}`].variables['Weeks'] = '52';

          outputJson['Final_Optimizer'].forEach((element: any) => {
            scenarioData[`Scenario ${scenario}`].variables[element.Media] = '0';
            scenarioData[`Scenario ${scenario}`].yearlySpendChartData.push(element.Yearly_Optimize_Spend);
            scenarioData[`Scenario ${scenario}`].labels.push(element.Media);
          });

          const otherSalesContribution = outputJson.Contributions_chart
            .filter((item: any) => item.Variables === 'Base' || item.Variables === 'Seasonality')
            .reduce((sum: any, item: any) => sum + item.Contributions, 0); // Corrected to 'Contributions'

          scenarioData[`Scenario ${scenario}`].predictedSales = outputJson[`Final_Optimizer`].reduce((sum: any, item: any) => {
            const contribution = typeof item.Contribution === 'string' ? parseFloat(item.Contribution) : item.Contribution;
            return sum + (isNaN(contribution) ? 0 : contribution);
          }, 0) + otherSalesContribution;

          // // Loop through all keys in the scenario object to find media variables
          // Object.keys(scenario).forEach((key) => {
          //   if (!['Scenario', 'Total Budget', 'Weeks'].includes(key)) {
          //     // If the key is a media variable, update the scenarioData for media variables
          //     scenarioData[scenarioKey].variables[key] = scenario[key];

          //     // Add the key to the mediaVariables array if it's not already there
          //     if (!mediaVariables.includes(key)) {
          //       mediaVariables.push(key);
          //     }

          //     // Optionally, you can update yearlySpendChartData and labels here, if necessary
          //     scenarioData[scenarioKey].yearlySpendChartData.push(outputJson[`${scenario["Scenario"]}_Final_Optimizer`].filter((item: any) => {
          //       
          //       return item.Media === key
          //     })[0]['Yearly_Optimize_Spend']);
          //     scenarioData[scenarioKey].labels.push(key);
          //   }
          // });
        });


        if (seasonalityData) {
          const labels = seasonalityData.map(
            (item: any) => item[dateVariables[0]]
          );
          const seasonData = seasonalityData.map((item: any) => parseFloat(item.Season.toFixed(2)));
          const trendData = seasonalityData.map((item: any) => parseFloat(item.Trend.toFixed(2)));

          setSeasonLabel(labels);

          setSeasonDataSet([
            {
              name: "Season",
              data: seasonData,
              borderColor: "rgba(75, 192, 192, 1)",
              backgroundColor: "rgba(75, 192, 192, 0.3)",
            },
          ]);

          setTrendDataSet([
            {
              name: "Trend",
              data: trendData,
              borderColor: "rgba(255, 99, 132, 1)",
              backgroundColor: "rgba(255, 99, 132, 0.3)",
            },
          ]);
        }

        const yoySpentData = outputJson['YoY_Sales_Contribution_chart'];

        if (yoySpentData) {
          // Define a list of colors to use for the bars
          const colorPalette = [
            "#FFC300", "#FF5733", "#C70039", "#900C3F", "#581845", "#1F618D", "#117A65", "#F39C12",
          ];

          // Extract all keys except "Year" to dynamically generate datasets
          const barDatasets = Object.keys(yoySpentData[0])
            .filter((key) => [...mediaVariables, "Base", "Seasonality"].includes(key)) // Exclude the "Year" field
            .map((key, index) => ({
              name: key.replace(/_/g, " "), // Replace underscores with spaces for better labels
              values: yoySpentData.map((item: any) => Math.round(item[key])), // Round values to zero decimal places
              color: colorPalette[index % colorPalette.length], // Assign colors cyclically
            }));

          // Prepare labels dynamically (Years)
          const labels = yoySpentData.map((item: any) => item.Year.toString());
          const lineDatasets: any = [];
          const chartProps: MultiBarLineChartProps = {
            labels,
            barDatasets,
            lineDatasets,
          };
          setMediaYOYSpentChart(chartProps);
        }

        const correlationMatrix = outputJson["CorrelationMatrix"];
        if (correlationMatrix) {
          // Extract xLabels and yLabels (assuming all keys are the same across the objects)
          const targetVariableKey = targetVariables[0];

          // Sort xLabels so that targetVariableKey comes first, and the rest are sorted alphabetically
          const xLabels = Object.keys(correlationMatrix[0]).sort((a, b) => {
            if (a === targetVariableKey) return -1; // Place targetVariableKey first
            if (b === targetVariableKey) return 1;  // Place targetVariableKey first
            return a.localeCompare(b); // Sort remaining items alphabetically
          });
          const yLabels = xLabels;  // Since it's a square matrix, x and y labels are the same

          const sortedMatrix = xLabels.map(label => {
            return correlationMatrix.find((row: { [x: string]: number; }) => row[label] === 1); // Find the row where the variable correlates with itself
          });
          // Create the zData matrix
          const zData = sortedMatrix.map((row: any) =>
            xLabels.map((x) => row[x])
          );

          // Setting the heatmap data
          setHeatMapData({
            xLabels,
            yLabels,
            zData,
          });
        }

        const similarityMatrix = outputJson["Similarity"];
        if (similarityMatrix) {
          // Extract xLabels and yLabels (assuming all keys are the same across the objects)
          const targetVariableKey = targetVariables[0];

          // Sort xLabels so that targetVariableKey comes first, and the rest are sorted alphabetically
          const xLabels = Object.keys(similarityMatrix[0]).sort((a, b) => {
            if (a === targetVariableKey) return -1; // Place targetVariableKey first
            if (b === targetVariableKey) return 1;  // Place targetVariableKey first
            return a.localeCompare(b); // Sort remaining items alphabetically
          });

          const yLabels = xLabels;  // Since it's a square matrix, x and y labels are the same

          // Create the zData matrix
          const zData = similarityMatrix.map((row: any) =>
            xLabels.map((x) => row[x])
          );

          // Setting the heatmap data
          setSimilarityHeatMapData({
            xLabels,
            yLabels,
            zData,
          });
        }

        const adstock = outputJson["adstock"];

        if (adstock) {
          // Group data by variable prefixes
          const groupedData = adstock.reduce((acc: any, item: any) => {
            // Split the variable name into components
            const parts = item.Var_Name.split("_");
            // Extract prefix (everything before the last component)
            const prefix = parts.slice(0, -1).join("_");
            // Extract metric (the last component)
            const metric = parts[parts.length - 1];

            // Initialize the object for the prefix if it doesn't exist
            if (!acc[prefix]) {
              acc[prefix] = { alpha: 0, gamma: 0, strength: 0, length: 0 };
            }

            // Add the Adstock_Value to the correct metric
            acc[prefix][metric] = item.Adstock_Value;

            return acc;
          }, {});

          // Prepare chart data
          const labels = Object.keys(groupedData);
          const barDatasets = [
            // {
            //   name: "Alpha",
            //   values: labels.map((label) => groupedData[label]?.alpha || 0),
            //   color: "#73C2FB", // Blue
            // },
            // {
            //   name: "Gamma",
            //   values: labels.map((label) => groupedData[label]?.gamma || 0),
            //   color: "#FF6F61", // Red
            // },
            {
              name: "Strength",
              values: labels.map((label) => groupedData[label]?.strength || 0),
              color: "#73C2FB", // Gold
            },
            {
              name: "Length",
              values: labels.map((label) => groupedData[label]?.length || 0),
              color: "#32CD32", // Green
            },
          ];

          const chartProps: any = {
            labels,
            barDatasets,
            lineDatasets: [], // No line datasets needed
          };
          setAdstockData(chartProps);
        }

        if (outputJson["VifFactors"]) {
          const records: TableRecord[] = [];
          outputJson["VifFactors"].map((item: { Variable: string, VIF: number }) => {
            records.push({
              variable: item.Variable.split('_')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' '),
              value: item.VIF,
            });
          });
          setMultiColinearityRecords(records);
        }

        setSalesDecompositionData(outputJson["Sales_Decomposition"]);
        setSalesDecompositionLabel(outputJson["Sales_Decomposition"].map((item: any, index: any) => index));
        const charts = generateSaturationCharts(outputJson);

        setSaturationCharts(charts as any);

        setContributionChartData(
          outputJson["Final_Optimizer"].map((item: any) =>
            Number.parseFloat(item.Contribution)
          )
        );
        setContributionChartLabel(
          outputJson["Final_Optimizer"].map((item: any) => item.Media)
        );

        setYearlyOptimizedSpendChartData(
          outputJson["Final_Optimizer"].map((item: any) =>
            Number.parseFloat(item.Yearly_Optimize_Spend)
          )
        );
        setYearlyOptimizedSpendChartLabel(
          outputJson["Final_Optimizer"].map((item: any) => item.Media)
        );

        if (outputJson["Contributions_chart"]) {
          const effectData = outputJson["Contributions_chart"].filter(
            (item: any) => item.Variables !== 'Base' && item.Variables !== 'Seasonality'
          );
          const labels = effectData.map((item: any) => item.Variables);
          const values = effectData.map((item: any) => Number(item.Contributions));

          setEffectShareLabel(labels);
          setEffectShareData(values);
        }

        setActualPredictedLabels(
          outputJson["Actual_vs_predicted"]
            .map((item: any) => {
              const dateKey = Object.keys(item).find(
                (key) => key.trim() === "Date"
              );
              if (dateKey) {
                return item[dateKey];
              }
              return null;
            })
            .filter((date: any) => date !== null)
        );
        setActualSales(
          outputJson["Actual_vs_predicted"].map((item: any) => item[targetVariables[0]])
        );
        setPredictedSales(
          outputJson["Actual_vs_predicted"].map((item: any) => item.prediction)
        );

        // Sort the array to prioritize "Seasonality" and "Base" by their Contributions values
        const sortedContributionsChart = outputJson.Contributions_chart.sort((a: any, b: any) => {
          // Check if both items are "Seasonality" or "Base"
          const isAImportant = a.Variables === "Seasonality" || a.Variables === "Base";
          const isBImportant = b.Variables === "Seasonality" || b.Variables === "Base";

          // Sort items with "Seasonality" or "Base" by Contributions
          if (isAImportant && isBImportant) {
            return b.Contributions - a.Contributions; // Descending order by Contributions
          }

          // Place "Seasonality" or "Base" items first
          if (isAImportant) return -1;
          if (isBImportant) return 1;

          return 0; // Keep original order for other items
        });

        // Set labels and data after sorting
        setWaterFallChartLabel(
          sortedContributionsChart.map((item: any) => item.Variables)
        );

        setWaterFallChartData(
          sortedContributionsChart.map((item: any) =>
            Number.parseInt(item.Contributions)
          )
        );

        setElasticityColumn(
          Object.keys(outputJson.Elasticity_Report[0]).sort((a, b) => {
            if (a === "MediaChannel") return -1; // Move "MediaChannel" to the first position
            if (b === "MediaChannel") return 1;
            return 0; // Maintain order for other keys
          }).map((key) => ({
            key: key.trim(),
            label: key.trim().replaceAll("_", " "),
          }))
        );
        setElasticityRecord(
          outputJson.Elasticity_Report
            // .filter((item: any) => item.MediaChannel !== 'Base' && item.MediaChannel !== 'Seasonality') // filter out "Base" and "Seasonality"
            .map((item: any) => {
              const record: TableRecord = {};
              Object.keys(item).forEach((key) => {
                // Replace underscores with spaces and capitalize each word for the key
                const formattedKey = key
                  .trim()
                  .split('_')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ');

                // Apply the same transformation to the value if it's a string
                let formattedValue = item[key];
                if (typeof formattedValue === 'string') {
                  formattedValue = formattedValue
                    .trim()
                    .split('_')
                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
                }

                record[formattedKey] = formattedValue;
              });
              return record;
            })
        );

      });
    });


  }, [id]);

  const effectShareValues = roiFinalData.map((item: any) => item.effect_share);
  const spendShareValues = roiFinalData.map((item: any) => item.spend_share);
  const roasValues = roiFinalData.map((item: any) => item.ROAS);

  const roiBarChartData = [
    {
      name: "Effect Share",
      values: effectShareValues,
      color: "rgba(54, 162, 235, 1)", // Teal-blue color
    },
    {
      name: "Spend Share",
      values: spendShareValues,
      color: "rgba(255, 159, 64, 1)", // Bright orange color
    }
  ];

  const roiLineChartData = [
    {
      name: "ROAS",
      values: roasValues,
      color: "rgba(54, 162, 235, 1)", // Teal-blue color
    },
  ];

  const runOptimizeMediaSpend = async (data: any) => {
    setLoading(true);
    data.jobConfigId = id;
    data.tenantId = getTenantId();
    const response = await axiosInstance.post(OPTIMIZE_SPEND_API, data);
    let outputJson = response.data.result;
    if (typeof outputJson === "string") {
      try {
        outputJson = JSON.parse(outputJson);
      } catch (error) {
        console.error("Failed to parse JSON string:", error);
      }
    }
    setLoading(false);
    window.location.reload();
  }

  const waterfallData = {
    labels: waterFallChartLabel,
    datasets: [
      {
        label: "Contributions",
        data: waterFallChartData,
      },
    ],
  };


  const twoLineChartData: LineChartProps = {
    labels: actualPredictedLabels,
    dataSets: [
      {
        name: "Actual Sales",
        data: actualSales,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Predicted Sales",
        data: predictedSales,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  const correlationChart: LineChartProps = {
    labels: correlationLabels,
    dataSets: [
      {
        name: "Media Variable",
        data: y1,
        borderColor: "#ff0000",
        backgroundColor: "rgba(255, 0, 0, 0.3)",
      },
      {
        name: "Target Variable",
        data: y2,
        borderColor: "#0000ff",
        backgroundColor: "rgba(0, 0, 255, 0.3)",
      },
    ],
    xAxisTitle: "Days",
    yAxisTitle: "Sales",
  };

  const onCorrelationMediaVariableChange = (data: any) => {
    
    fetchCorrelationData(data.target.value, targetVariables[0], dateVariables[0]);
  }

  const onMarketLocationChange = (data: any) => {
    setSelectedMarket(data.target.value);
  }

  const fetchCorrelationData = (y1: string, y2: string, x: string) => {
    axiosInstance.post(MMM_INPUT_DATA, {
      jobConfigId: id,
      tenantId: getTenantId(),
      y1,
      y2,
      x
    }).then((response) => {
      setCorrelationLabels(
        response.data["date"]
      );
      setY1(
        normalizeData(response.data["y1"])
      );
      setY2(
        normalizeData(response.data["y2"])
      );
    });
  }

  return (
    <NavBar>
      <div className="p-8 flex flex-col">
        <div className="flex justify-between">
          <div className="text-sm breadcrumbs self-end">
            <ul>
              {breadcrumbs.map((entity) => (
                <li key={entity.url}>
                  <Link to={entity.url} className="font-mono underline">
                    {entity.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="divider"></div>

        {trendDataSet.length > 0 && seasonLabel.length > 0 && (
          <div className="flex justify-between mx-8">
            <div style={{ width: "calc(50% - 8px)" }} className=" w-1/2   mt-6 mr-6">
              <Maximize title="Seasonality">
                <div className="overflow-auto">
                  <LineChart
                    labels={seasonLabel}
                    dataSets={seasonDataSet}
                    yAxisTitle="Level Component For Sale"
                    xAxisTitle="Date"
                  />
                </div>
              </Maximize>
            </div>
            <div style={{ width: "calc(50% - 8px)" }} className="   mt-6">
              <Maximize title="Trend">
                <div className="overflow-auto">
                  <LineChart
                    labels={seasonLabel}
                    dataSets={trendDataSet}
                    xAxisTitle="Date"
                    yAxisTitle="Level Component For Sale"
                  />
                </div>
              </Maximize>
            </div>
          </div>
        )}

        {correlationChart && mediaVariables.length > 0 && (
          <div className="mx-8 space-y-6">
            {/* First row: Spend Share and Effect Share side by side */}
            <div className="flex gap-6">
              {historicSpendContributionData && historicSpendContributionLabel && (
                <div className="flex-1">
                  <Maximize title="Spend Share">
                    <div style={{ height: "400px" }}>
                      <PieChartECharts
                        data={historicSpendContributionData}
                        labels={historicSpendContributionLabel}
                      />
                    </div>
                  </Maximize>
                </div>
              )}
              {effectShareData && effectShareLabel && (
                <div className="flex-1">
                  <Maximize title="Effect Share">
                    <div style={{ height: "400px" }}>
                      <PieChartECharts
                        data={effectShareData}
                        labels={effectShareLabel}
                      />
                    </div>
                  </Maximize>
                </div>
              )}
            </div>

            {/* Second row: Correlation Chart */}
            {correlationChart && (
              <div className="w-full">
                <Maximize title="Correlation">
                  <div style={{ height: "400px" }}>
                    <div className="flex gap-4 mb-4 px-4">
                      {marketLocations.length > 0 && (
                        <select className="select select-ghost select-sm w-40" onChange={onMarketLocationChange} value={selectedMarket}>
                          {marketLocations.map((location) => (
                            <option key={location} value={location}>{location.toUpperCase()}</option>
                          ))}
                        </select>
                      )}
                      <select className="select select-ghost select-sm w-40" onChange={onCorrelationMediaVariableChange}>
                        {mediaVariables.map((theme) => (
                          <option key={theme} value={theme}>{theme.toUpperCase()}</option>
                        ))}
                      </select>
                    </div>
                    <LineChart {...correlationChart} />
                  </div>
                </Maximize>
              </div>
            )}

            {/* Other charts */}
            {heatMapData && (
              <div className="w-full">
                <Maximize title={"Correlation Matrix"}>
                  <HeatMap data={heatMapData} />
                </Maximize>
              </div>
            )}
            {similarityHeatMapData && (
              <div className="w-full">
                <Maximize title={"Similarity Matrix"}>
                  <HeatMap data={similarityHeatMapData} />
                </Maximize>
              </div>
            )}
            {adstockData && (
              <div className="w-full">
                <Maximize title={"Adstock Chart"}>
                  <div className="flex justify-between items-center mb-2 px-4 py-4">
                    <div></div>
                    <button
                      onClick={() => setShowChartLabels(!showChartLabels)}
                      className="px-3 py-1 text-sm text-[#143969] border border-[#143969] rounded hover:bg-[#143969] hover:text-white transition-colors"
                    >
                      {showChartLabels ? 'Hide Labels' : 'Show Labels'}
                    </button>
                  </div>
                  <div style={{ height: "400px" }}>
                    <MultiBarLineChartECharts {...adstockData} showLabels={showChartLabels} />
                  </div>
                </Maximize>
              </div>
            )}
            {mediaYOYSpentChart && (
              <div className="w-full">
                <Maximize title="YOY Media Contribution">
                  <div className="flex justify-between items-center mb-2 px-4 py-4">
                    <div></div>
                    <button
                      onClick={() => setShowChartLabels(!showChartLabels)}
                      className="px-3 py-1 text-sm text-[#143969] border border-[#143969] rounded hover:bg-[#143969] hover:text-white transition-colors"
                    >
                      {showChartLabels ? 'Hide Labels' : 'Show Labels'}
                    </button>
                  </div>
                  <div style={{ height: "400px" }}>
                    <MultiBarLineChartECharts
                      labels={mediaYOYSpentChart.labels}
                      barDatasets={mediaYOYSpentChart.barDatasets}
                      lineDatasets={mediaYOYSpentChart.lineDatasets}
                      showLabels={showChartLabels}
                    />
                  </div>
                </Maximize>
              </div>
            )}
            {elasticityColumn && elasticityColumn.length > 0 && elasticityRecord && elasticityRecord.length > 0 && (
              <div className="w-full">
                <Maximize title="Elasticity Report">
                  <div className="w-full px-2">
                    <Table columns={elasticityColumn} records={elasticityRecord} />
                  </div>
                </Maximize>
              </div>
            )}
            {multiColinearityRecords.length > 0 && multiColinearityColumn && (
              <div className="w-full">
                <Maximize title="Multi Collinearity Check">
                  <Table
                    columns={multiColinearityColumn}
                    records={multiColinearityRecords}
                  />
                </Maximize>
              </div>
            )}
            {waterFallChartData && waterFallChartLabel && (
              <>
                <div className="w-full">
                  <Maximize title="Accumulated Waterfall Chart">
                    <div style={{ height: "400px" }}>
                      <WaterFallChart
                        data={waterFallChartData}
                        labels={waterFallChartLabel}
                      />
                    </div>
                  </Maximize>
                </div>
                <div className="divider"></div>
                <div className="w-full">
                  <Maximize title="Accumulated Waterfall Chart">
                    <div style={{ height: "400px" }}>
                      <WaterFallChart
                        data={waterFallChartData}
                        labels={waterFallChartLabel}
                        isBase100={true}
                      />
                    </div>
                  </Maximize>
                </div>
              </>
            )}

            {twoLineChartData && modelAccuracy && modelAccuracy[0] && (
              <div className="w-full">
                <Maximize title="Actual Vs Predicted">
                  <div style={{ height: "400px" }}>
                    <div className="flex justify-center">
                      <div className="mx-12">MAPE: {(modelAccuracy[0]["MAPE"] * 100 as number).toFixed(2)} %</div>
                      <div className="mr-12">R-Square: {(modelAccuracy[0]["R_Squared"] * 100 as number).toFixed(2)} %</div>
                    </div>
                    <LineChart {...twoLineChartData} />
                  </div>
                </Maximize>
              </div>
            )}

            {salesDecompositionData && salesDecompositionData.length > 0 && salesDecompositionLabel && (
              <div className="w-full">
                <Maximize title="Sales Decomposition">
                  <div style={{ height: "400px" }}>
                    <GradientStackedAreaChart
                      data={salesDecompositionData}
                      labels={salesDecompositionLabel}
                    />
                  </div>
                </Maximize>
              </div>
            )}

            {saturationCharts && saturationCharts.length > 0 && (
              <div>
                <h1 className="text-2xl font-bold text-center my-8">
                  Saturation Curves
                </h1>
                <div className="flex flex-wrap mx-8 justify-between">
                  {saturationCharts}
                </div>
              </div>
            )}



            {contributionChartData?.length > 0 && (
              <div className="w-full">
                <div className="divider"></div>
                <h1 className="text-2xl font-bold text-center mt-8 mb-2">
                  Scenario Planner
                </h1>
                <div className="w-full mt-2 mb-8 flex justify-center items-center">
                  <ScenarioTable
                    scenarioData={scenarioData}
                    callback={runOptimizeMediaSpend}
                    isLoading={isLoading}
                  />
                </div>
              </div>
            )}
          </div>
        )}
        <Chat 
          jobConfig={jobConfig} 
          tenantId={getTenantId()} 
          apiEndpoint={MMM_CHAT_API}
        />
      </div>
    </NavBar>
  );
};

export default MMMDashboard;
