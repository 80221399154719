// Utility function to get random color
export const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

// Utility function for random color with opacity
export const getRandomColorWithOpacity = (opacity: number, baseColor?: string) => {
    const color = baseColor || getRandomColor();
    return color + Math.floor(opacity * 255).toString(16);
};
